import {
  UnitActionTypes,
  GET_UNIT_S,
} from 'consts/unitActionTypes';
import { initialState, UnitState } from './initialState';

const unitReducer = (state = initialState, action: UnitActionTypes): UnitState => {
  switch (action.type) {
    case GET_UNIT_S: {
      const { units } = action.payload;
      const unitCategories = [...new Set(units.map((item) => item.category))];
      return {
        ...state,
        units,
        unitCategories,
      };
    }

    default:
      return state;
  }
};

export default unitReducer;
