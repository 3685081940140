import React, { FC } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Button, Drawer, Input, Select } from '@flexboxapps/flbx-webapp-ui';
import { setCreateWarehouseDrawer, setWarehouseInput, createWarehouse } from 'actions/warehouseActions';
import { RootState } from 'reducers/rootReducer';
import { Location } from 'reducers/locations/initialState';
import './add-warehouse-drawer.scss';

const AddWarehouseDrawer: FC = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const {
    drawerVisible, title,
    creatingInProgress, warehouseTypes,
    type, location: selectedLocation,
  } = useSelector(({ warehouse }: RootState) => warehouse);

  const {
    locations,
  } = useSelector(({ location }: RootState) => location);

  return (
    <Drawer
      className="app-drawer drawer-add-category"
      title={t('drawers.addWarehouse.title')}
      onClose={() => dispatch(setCreateWarehouseDrawer('create', false))}
      visible={drawerVisible.create}
    >
      <div className="drawer-inner-container">
        <div className="warehouse-drawer-input-container">
          <Input
            label={t('drawers.addWarehouse.warehouseName').toUpperCase()}
            placeholder={t('drawers.addWarehouse.warehouseNamePlaceholder')}
            value={title}
            onChange={(event) => { dispatch(setWarehouseInput('title', event.target.value)); }}
            borderColor="#F2C94C"
          />
          <Select
            label={t('drawers.addWarehouse.warehouseLocation').toUpperCase()}
            placeholder={t('drawers.addWarehouse.warehouseLocationPlaceholder')}
            value={selectedLocation}
            options={locations.map((item: Location) => ({
              label: item.title,
              value: item._id,
            }))}
            onChange={(value) => { dispatch(setWarehouseInput('location', value)); }}
            borderColor="#F2C94C"
            size="large"
          />
          <Select
            label={t('drawers.addWarehouse.warehouseType').toUpperCase()}
            placeholder={t('drawers.addWarehouse.warehouseTypePlaceholder')}
            value={type}
            options={warehouseTypes.map((item) => ({
              label: t(`drawers.addWarehouse.type.${item.label}`),
              value: item.value,
            }))}
            onChange={(value) => { dispatch(setWarehouseInput('type', value)); }}
            borderColor="#F2C94C"
            size="large"
          />
        </div>
      </div>
      <div className="drawer-actions">
        <Button
          label={t('app.common.buttons.cancel')}
          disabled={creatingInProgress}
          onClick={() => dispatch(setCreateWarehouseDrawer('create', false))}
          type="ghost"
        />
        <Button
          label={t('app.common.buttons.create')}
          type="secondary"
          disabled={title === '' || !type || !selectedLocation}
          loading={creatingInProgress}
          onClick={() => { dispatch(createWarehouse()); }}
        />
      </div>
    </Drawer>
  );
};

export default AddWarehouseDrawer;
