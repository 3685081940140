import { all } from 'redux-saga/effects';
import { appSagas } from './appSaga';
import { userSagas } from './userSaga';
import { locationSagas } from './locationSaga';
import { warehouseSagas } from './warehouseSaga';
import { unitSagas } from './unitSaga';
import { productSagas } from './productSaga';
import { stockSagas } from './stockSaga';

export default function* rootSaga() {
  yield all([
    ...appSagas,
    ...userSagas,
    ...locationSagas,
    ...warehouseSagas,
    ...unitSagas,
    ...productSagas,
    ...stockSagas,
  ]);
}
