import {
  StockActionTypes,
  GET_STOCK_S,
  SET_STOCK_DRAWER,
  SET_STOCK_INPUT,
  CREATE_STOCK_R,
  CREATE_STOCK_S,
  CREATE_STOCK_F,
  UPDATE_STOCK_R,
  UPDATE_STOCK_S,
  UPDATE_STOCK_F,
} from 'consts/stockActionTypes';
import { initialState, StockState } from './initialState';

const stockReducer = (state = initialState, action: StockActionTypes): StockState => {
  switch (action.type) {
    case GET_STOCK_S: {
      const { stocks } = action.payload;
      return {
        ...state,
        stocks,
      };
    }

    case SET_STOCK_DRAWER: {
      const { key, value } = action.payload;
      return {
        ...initialState,
        stocks: state.stocks,
        drawerVisible: {
          ...state.drawerVisible,
          [key]: value,
        },
      };
    }

    case SET_STOCK_INPUT: {
      const { key, value } = action.payload;
      return {
        ...state,
        [key]: value,
      };
    }

    case CREATE_STOCK_R:
      return {
        ...state,
        creatingInProgress: true,
      };

    case CREATE_STOCK_S:
      return {
        ...state,
        creatingInProgress: false,
        drawerVisible: {
          ...state.drawerVisible,
          create: false,
        },
      };

    case CREATE_STOCK_F:
      return {
        ...state,
        creatingInProgress: false,
      };

    case UPDATE_STOCK_R:
      return {
        ...state,
        updatingInProgress: true,
      };

    case UPDATE_STOCK_S:
      return {
        ...state,
        updatingInProgress: false,
        drawerVisible: {
          ...state.drawerVisible,
          update: false,
        },
      };

    case UPDATE_STOCK_F:
      return {
        ...state,
        updatingInProgress: false,
      };

    default:
      return state;
  }
};

export default stockReducer;
