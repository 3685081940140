import { Sorts } from '@flexboxapps/flbx-webapp-ui';
import { SelectValue } from 'antd/lib/select';
import { Stock } from 'reducers/stocks/initialState';
import {
  GET_STOCK_R, StockGetStockRequested,
  SET_STOCK_INPUT, StockSetInput,
  SET_STOCK_DRAWER, StockSetDrawer,
  CREATE_STOCK_R, StockCreateStockRequested,
  UPDATE_STOCK_R, StockUpdateStockRequested,
} from 'consts/stockActionTypes';

export const getStocks = (value?: Sorts | null, key?: string, term?: string): StockGetStockRequested => ({
  type: GET_STOCK_R,
  payload: {
    value,
    key,
    term,
  },
});

export const setStockInput = (key: string, value?: string | number | boolean | SelectValue | string[]): StockSetInput => ({
  type: SET_STOCK_INPUT,
  payload: {
    key,
    value,
  },
});

export const setCreateStockDrawer = (key: string, value: boolean): StockSetDrawer => ({
  type: SET_STOCK_DRAWER,
  payload: {
    key,
    value,
  },
});

export const createStock = (): StockCreateStockRequested => ({
  type: CREATE_STOCK_R,
});

export const updateStock = (data: Stock): StockUpdateStockRequested => ({
  type: UPDATE_STOCK_R,
  payload: {
    data,
  },
});
