import React, { FC } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInventory, faCashRegister } from '@fortawesome/pro-duotone-svg-icons';
import { ReactComponent as RightArrow } from 'assets/right-arrow.svg';
import './location-card.scss';

type LocationCardProps = {
  location: string,
  onClick: () => void,
  numberOfWarehouse?: number,
  numberOfPos?: number,
};

const LocationCard: FC<LocationCardProps> = ({ location, onClick, numberOfWarehouse, numberOfPos }) => (
  <div
    role="button"
    tabIndex={0}
    className="location-card-container"
    onClick={onClick}
  >
    <div className="location-arrow"><RightArrow /></div>
    <div className="location-title">{location}</div>
    <div className="location-card-box-container">
      <div className={`location-card-box${!numberOfWarehouse && ' empty'}`}>
        <div className="location-card-icon"><FontAwesomeIcon icon={faInventory} /></div>
        {
          numberOfWarehouse && (
            <div className="location-card-number">{numberOfWarehouse}</div>
          )
        }
      </div>
      <div className={`location-card-box${!numberOfPos && ' empty'}`}>
        <div className="location-card-icon"><FontAwesomeIcon icon={faCashRegister} /></div>
        {
          numberOfPos && (
            <div className="location-card-number">{numberOfPos}</div>
          )
        }
      </div>
    </div>
  </div>
);

export default LocationCard;
