import { takeEvery, call, put, select } from 'redux-saga/effects';
import {
  GET_WAREHOUSE_R, GET_WAREHOUSE_F, GET_WAREHOUSE_S, WarehouseGetWarehouseRequested,
  CREATE_WAREHOUSE_R, CREATE_WAREHOUSE_F, CREATE_WAREHOUSE_S,
  UPDATE_WAREHOUSE_R, UPDATE_WAREHOUSE_F, UPDATE_WAREHOUSE_S, WarehouseUpdateWarehouseRequested,
} from 'consts/warehouseActionTypes';
import { Sorts } from '@flexboxapps/flbx-webapp-ui';
import * as API from 'services/api';
import { RootState } from 'reducers/rootReducer';

const getWarehouseStore = (state: RootState) => state.warehouse;

function* getWarehouses(action: WarehouseGetWarehouseRequested) {
  try {
    const { value, key } = action.payload;
    const query = {
      $params: JSON.stringify({
        title: { $regex: '', $options: 'i' },
        deprecated: false,
      }),
      $sort: JSON.stringify({
        [key || 'createdAt']: value || Sorts.asc,
      }),
    };
    const data: [any] = yield call(API.findDataService, 'warehouses', '/warehouses', query);
    yield put({
      type: GET_WAREHOUSE_S,
      payload: {
        warehouses: data,
      },
    });
  } catch (error) {
    yield put({
      type: GET_WAREHOUSE_F,
      payload: {
        errorMessage: error.message,
      },
    });
    console.log(error); // eslint-disable-line
  }
}

function* createWarehouse() {
  try {
    const { title, location, type } = yield select(getWarehouseStore);
    const updatedData = {
      title,
      location,
      type,
    };
    yield call(API.createDataService, 'warehouses', '/warehouses', updatedData);
    yield put({
      type: CREATE_WAREHOUSE_S,
    });
    yield put({
      type: GET_WAREHOUSE_R,
      payload: {
        value: Sorts.asc,
        key: 'createdAt',
        term: '',
      },
    });
  } catch (error) {
    yield put({
      type: CREATE_WAREHOUSE_F,
      payload: {
        errorMessage: error.message,
      },
    });
    console.log(error); // eslint-disable-line
  }
}

function* updateWarehouse(action: WarehouseUpdateWarehouseRequested) {
  try {
    const { data } = action.payload;
    const updatedData = {
      title: data.title,
      location: data.location,
      type: data.type,
    };
    yield call(API.patchDataService, 'warehouses', '/warehouses', data._id, updatedData);
    yield put({
      type: UPDATE_WAREHOUSE_S,
    });
    yield put({
      type: GET_WAREHOUSE_R,
      payload: {
        value: Sorts.asc,
        key: 'createdAt',
        term: '',
      },
    });
  } catch (error) {
    yield put({
      type: UPDATE_WAREHOUSE_F,
      payload: {
        errorMessage: error.message,
      },
    });
    console.log(error); // eslint-disable-line
  }
}

export const warehouseSagas = [
  takeEvery(GET_WAREHOUSE_R, getWarehouses),
  takeEvery(CREATE_WAREHOUSE_R, createWarehouse),
  takeEvery(UPDATE_WAREHOUSE_R, updateWarehouse),
];
