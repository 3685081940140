import { takeEvery } from 'redux-saga/effects';
import i18next from 'i18next';
import {
  APP_CHANGE_LANGUAGE, AppChangeLanguageType,
} from 'consts/appActionTypes';

function changeLanguage(action: AppChangeLanguageType) {
  i18next.changeLanguage(action.payload.lang);
}

export const appSagas = [
  takeEvery(APP_CHANGE_LANGUAGE, changeLanguage),
];
