import { takeEvery, call, put, select } from 'redux-saga/effects';
import {
  GET_PRODUCT_R, GET_PRODUCT_F, GET_PRODUCT_S, ProductGetProductRequested,
  CREATE_PRODUCT_R, CREATE_PRODUCT_F, CREATE_PRODUCT_S,
  UPDATE_PRODUCT_R, UPDATE_PRODUCT_F, UPDATE_PRODUCT_S, ProductUpdateProductRequested,
} from 'consts/productActionTypes';
import { Sorts } from '@flexboxapps/flbx-webapp-ui';
import * as API from 'services/api';
import { RootState } from 'reducers/rootReducer';
import { StorageType } from 'models';

const getProductStore = (state: RootState) => state.product;

function* getProducts(action: ProductGetProductRequested) {
  try {
    const { value, key, term } = action.payload;
    const getKey = () => {
      if (key) {
        return key === 'title' ? 'displayName' : key;
      }
      return 'createdAt';
    };
    const query = {
      $params: JSON.stringify({
        displayName: { $regex: term || '', $options: 'i' },
        deprecated: false,
      }),
      $sort: JSON.stringify({
        [getKey()]: value || Sorts.asc,
      }),
    };
    const data: [any] = yield call(API.findDataService, 'products', '/products', query);
    yield put({
      type: GET_PRODUCT_S,
      payload: {
        products: data,
      },
    });
  } catch (error) {
    yield put({
      type: GET_PRODUCT_F,
      payload: {
        errorMessage: error.message,
      },
    });
    console.log(error); // eslint-disable-line
  }
}

function* createProduct() {
  try {
    const {
      displayName, storageType,
      storageUnit, defaultQuantity,
      perPieceUnit, storageQuantity,
      perPieceQuantity, variations,
    } = yield select(getProductStore);
    const getUpdatedData = () => {
      const commonData = {
        displayName,
        storageType,
        defaultQuantity,
        variations: variations.filter((item: string) => item),
      };
      switch (storageType) {
        case StorageType.WHOLE:
          return {
            ...commonData,
            storageUnit,
          };

        case StorageType.MEASURED:
          return {
            ...commonData,
            perPieceUnit,
          };

        case StorageType.UNFOLDABLE:
          return {
            ...commonData,
            storageUnit,
            storageQuantity,
            perPieceUnit,
            perPieceQuantity,
          };

        default:
          return {};
      }
    };

    yield call(API.createDataService, 'products', '/products', getUpdatedData());
    yield put({
      type: CREATE_PRODUCT_S,
    });
    yield put({
      type: GET_PRODUCT_R,
      payload: {
        value: Sorts.asc,
        key: 'createdAt',
        term: '',
      },
    });
  } catch (error) {
    yield put({
      type: CREATE_PRODUCT_F,
      payload: {
        errorMessage: error.message,
      },
    });
    console.log(error); // eslint-disable-line
  }
}

function* updateProduct(action: ProductUpdateProductRequested) {
  try {
    const { data } = action.payload;
    const getUpdatedData = () => {
      const commonData = {
        displayName: data.displayName,
        storageType: data.storageType,
        defaultQuantity: data.defaultQuantity,
      };
      switch (data.storageType) {
        case StorageType.WHOLE:
          return {
            ...commonData,
            storageUnit: data.storageUnit,
          };

        case StorageType.MEASURED:
          return {
            ...commonData,
            perPieceUnit: data.perPieceUnit,
          };

        case StorageType.UNFOLDABLE:
          return {
            ...commonData,
            storageUnit: data.storageUnit,
            storageQuantity: data.storageQuantity,
            perPieceUnit: data.perPieceUnit,
            perPieceQuantity: 1,
          };

        default:
          return {};
      }
    };

    yield call(API.patchDataService, 'products', '/products', data._id, getUpdatedData());
    yield put({
      type: UPDATE_PRODUCT_S,
    });
    yield put({
      type: GET_PRODUCT_R,
      payload: {
        value: Sorts.asc,
        key: 'createdAt',
        term: '',
      },
    });
  } catch (error) {
    yield put({
      type: UPDATE_PRODUCT_F,
      payload: {
        errorMessage: error.message,
      },
    });
    console.log(error); // eslint-disable-line
  }
}

export const productSagas = [
  takeEvery(GET_PRODUCT_R, getProducts),
  takeEvery(CREATE_PRODUCT_R, createProduct),
  takeEvery(UPDATE_PRODUCT_R, updateProduct),
];
