import {
  WarehouseActionTypes,
  GET_WAREHOUSE_S,
  SET_WAREHOUSE_DRAWER,
  SET_WAREHOUSE_INPUT,
  CREATE_WAREHOUSE_R,
  CREATE_WAREHOUSE_S,
  CREATE_WAREHOUSE_F,
  UPDATE_WAREHOUSE_R,
  UPDATE_WAREHOUSE_S,
  UPDATE_WAREHOUSE_F,
} from 'consts/warehouseActionTypes';
import { initialState, WarehouseState } from './initialState';

const warehouseReducer = (state = initialState, action: WarehouseActionTypes): WarehouseState => {
  switch (action.type) {
    case GET_WAREHOUSE_S: {
      const { warehouses } = action.payload;
      return {
        ...state,
        warehouses,
      };
    }

    case SET_WAREHOUSE_DRAWER: {
      const { key, value } = action.payload;
      return {
        ...initialState,
        warehouses: state.warehouses,
        drawerVisible: {
          ...state.drawerVisible,
          [key]: value,
        },
      };
    }

    case SET_WAREHOUSE_INPUT: {
      const { key, value } = action.payload;
      return {
        ...state,
        [key]: value,
      };
    }

    case CREATE_WAREHOUSE_R:
      return {
        ...state,
        creatingInProgress: true,
      };

    case CREATE_WAREHOUSE_S:
      return {
        ...state,
        creatingInProgress: false,
        drawerVisible: {
          ...state.drawerVisible,
          create: false,
        },
      };

    case CREATE_WAREHOUSE_F:
      return {
        ...state,
        creatingInProgress: false,
      };

    case UPDATE_WAREHOUSE_R:
      return {
        ...state,
        updatingInProgress: true,
      };

    case UPDATE_WAREHOUSE_S:
      return {
        ...state,
        updatingInProgress: false,
        drawerVisible: {
          ...state.drawerVisible,
          update: false,
        },
      };

    case UPDATE_WAREHOUSE_F:
      return {
        ...state,
        updatingInProgress: false,
      };

    default:
      return state;
  }
};

export default warehouseReducer;
