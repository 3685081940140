import React, { FC } from 'react';
import { Layout } from 'antd';
import { useSelector, useDispatch } from 'react-redux';
import { LanguageSelector } from '@flexboxapps/flbx-webapp-ui';
import { changeLanguage } from 'actions/appActions';
import { RootState } from 'reducers/rootReducer';
import './login-layout.scss';

const { Content, Footer } = Layout;

type LoginLayoutProps = {
  children?: JSX.Element | JSX.Element[],
};

const LoginLayout: FC<LoginLayoutProps> = ({ children }) => {
  const dispatch = useDispatch();
  const { lang } = useSelector(({ app }: RootState) => app);
  return (
    <Layout className="layout layout-login">
      <Content>
        {children}
      </Content>
      <Footer style={{ textAlign: 'center' }}>
        {`FlexboxApp ©${new Date().getFullYear()}`}
        <LanguageSelector selectedLang={lang} onChange={(key: string) => dispatch(changeLanguage(key))} />
      </Footer>
    </Layout>
  );
};

export default LoginLayout;
