export default {
  translation: {
    app: {
      common: {
        buttons: {
          logout: 'Kijelentkezés',
          back: 'Vissza',
          restart: 'Újrakezdés',
          reset: 'Visszaállítás',
          yes: 'Igen',
          no: 'Nem',
          add: 'Hozzáadás',
          cancel: 'Mégsem',
          close: 'Bezár',
          edit: 'Szerkesztés',
          create: 'Létrehozás',
          save: 'Mentés',
          update: 'Módosítás',
        },
        colors: {
          green: 'zöld',
          blue: 'kék',
          purple: 'lila',
          yellow: 'sárga',
          orange: 'narancs',
          red: 'piros',
        },
        noData: 'Nincs megjeleníthető adat!',
      },
      languages: {
        hu: 'magyar',
        en: 'angol',
      },
      unitType: {
        AREA: 'terület',
        MASS: 'tömeg',
        LENGTH: 'hosszúság',
        VOLUME: 'térfogat',
        OTHER: 'egyéb',
      },
    },
    pages: {
      update: {
        availableUpdate: 'Elérhető egy új frissítés!',
        updateApp: 'App frissítése',
      },
      login: {
        title: 'Bejelentkezés',
        subtitle: 'Adja meg a bejelentkezási adatait.',
        inputs: {
          email: 'Email cím',
          password: 'Jelszó',
          login: 'Bejelentkezés',
        },
        errors: {
          badCredentials: 'Hibás email cím vagy jelszó',
          emptyEmail: 'Bejelentkezéshez használt email cím:',
          emptyPassword: 'Fiókhoz tartozó jelszó:',
        },
      },
      dashboard: {
        title: 'Üdvözöljük',
        next: 'Tovább',
        welcomeSubtitle: 'Készen állunk, vágjunk bele!',
        infocards: {
          categories: 'Termékek \nés kategóriák',
          categorysub: 'Nem kell útbaigazítás',
          sales: 'Napi eladások',
          locations: 'Helyszínek',
          discounts: 'Kedvezmények',
          warehouses: 'Raktárak',
          products: 'Termékek',
          stocks: 'Készletek',
        },
      },
      categories: {
        title: 'Kategóriák \nés termékek',
        labels: {
          titles: {
            hu: 'név',
            en: 'angol név',
          },
        },
        inputs: {
          titles: {
            hu: 'magyar nyelvű elnevezés',
            en: 'angol nyelvű elnevezés',
          },
          parentCategory: 'szülő kategória',
          colorCode: 'színkód',
        },
      },
      sales: {
        title: 'Eladások',
        emptyPage: {
          title: 'Nincs megjeleníthető eladási tétel',
          loading: 'Eladási adatok betöltése...',
        },
      },
      locations: {
        title: 'Helyszínek',
        subtitle: 'Minden egy helyen',
        buttonTitle: 'Új helyszín létrehozása',
      },
      discounts: {
        title: 'Kedvezmények',
        subtitle: 'Minden egy helyen',
        buttonTitle: 'Kedvezmény létrehozása',
      },
      warehouses: {
        title: 'Raktárak',
        subtitle: 'Minden egy helyen',
        buttonTitle: 'Új raktár létrehozása',
      },
      products: {
        title: 'Termékek',
        subtitle: 'Minden egy helyen',
        buttonTitle: 'Új termék létrehozása',
      },
      stocks: {
        title: 'Készletek',
        subtitle: 'Minden egy helyen',
        buttonTitle: 'Új készlet létrehozása',
        columns: {
          productName: 'Termék neve',
          quantity: 'Mennyiség',
          warehouse: 'Helyszín',
        },
      },
    },
    drawers: {
      addCategory: {
        title: 'Új kategória létrehozása',
        subtitle: 'Adja meg az elnevezéseket',
      },
      addDiscount: {
        title: 'Kedvezmény létrehozása',
        subtitle: 'Adja meg a kedvezmény adatait',
        discountName: 'Kedvezmény neve',
        discountNamePlaceholder: 'Adja meg a kedvezmény nevét',
        discountValue: 'Kedvezmény',
        discountValuePlaceholder: 'Adja meg a kedvezmény értékét',
        deprecatedTitle: 'Legyen listázva?',
      },
      addLocation: {
        title: 'Helyszín létrehozása',
        updateTitle: 'Helyszín módosítása',
        subtitle: 'Adja meg a helyszín adatait',
        locationName: 'Helyszín neve',
        locationNamePlaceholder: 'Adja meg a helyszín nevét',
      },
      addWarehouse: {
        title: 'Raktár létrehozása',
        updateTitle: 'Raktár módosítása',
        subtitle: 'Adja meg a raktár adatait',
        warehouseName: 'Raktár neve',
        warehouseNamePlaceholder: 'Adja meg a raktár nevét',
        warehouseLocation: 'Raktár helyszíne',
        warehouseLocationPlaceholder: 'Adja meg a raktár helyszínét',
        warehouseType: 'Raktár típusa',
        warehouseTypePlaceholder: 'Adja meg a raktár típusát',
        type: {
          MATERIAL: 'Anyag raktár',
          PRODUCT: 'Termék raktár',
        },
      },
      addProduct: {
        title: 'Termék létrehozása',
        updateTitle: 'Termék módosítása',
        subtitle: 'Adja meg a termék adatait',
        productName: 'Termék neve',
        productNamePlaceholder: 'Adja meg a termék nevét',
        storageType: 'Tárolás módja',
        storageTypePlaceholder: 'Adja meg a tárolás módját',
        storageUnit: 'Tárolási mértékegység',
        storageUnitPlaceholder: 'Adja meg a tárolási mértékegységet',
        perPieceUnit: 'Egységnyi termék mértékegysége',
        perPieceUnitPlaceholder: 'Adja meg az egységnyi termék mértékegységét',
        storageQuantity: 'Bontáskor keletkező mennyiség',
        storageQuantityPlaceholder: 'Adja meg a bontáskor keletkező mennyiséget',
        defaultQuantity: 'Leggyakrabban eladott mennyiség',
        addVariation: 'Variáció hozzáadása',
        variation: 'Variáció neve',
        variationPlaceholder: 'Adja meg a variáció nevét',
        type: {
          WHOLE: 'egész',
          UNFOLDABLE: 'megbontható',
          MEASURED: 'kimért/halmozott',
        },
      },
      addStock: {
        title: 'Készlet létrehozása',
        updateTitle: 'Készlet módosítása',
        subtitle: 'Adja meg a készlet adatait',
        product: 'Termék',
        productPlaceholder: 'Válasszon terméket',
        stockType: 'Készlet típusa',
        stockTypePlaceholder: 'Adja meg a készlet típusát',
        warehouse: 'Raktár',
        warehousePlaceholder: 'Válasszon raktárat',
        typeOfSettlement: 'Elszámolás típusa',
        typeOfSettlementPlaceholder: 'Adja meg az elszámolás típusát',
        settlements: {
          INSTANTLY: 'Azonnali',
          MANUALLY: 'Manuálisan',
          DAILY: 'Napi',
        },
        stockTypes: {
          WHOLE: 'Egész',
          OPENED: 'Bontott',
        },
        startingQuantity: 'Kezdő mennyiség',
      },
    },
  },
};
