import { takeEvery, call, put, delay } from 'redux-saga/effects';
import { CognitoUserSession } from 'amazon-cognito-identity-js';
import {
  USER_LOGIN_R, USER_LOGIN_S, USER_LOGIN_F, UserLoginRequestedType,
  RESTORE_USER_BY_TOKEN_R, RESTORE_USER_BY_TOKEN_S, RESTORE_USER_BY_TOKEN_F, RestoreUserByTokenRequestedType,
  USER_LOGOUT_R, USER_LOGOUT_S, USER_LOGOUT_F,
} from 'consts/userActionTypes';
import { history } from 'services/config';
import * as API from 'services/api';

function* login(action: UserLoginRequestedType) {
  try {
    const { email, password } = action.payload;
    yield call(API.login, email, password);
    yield delay(1000);
    yield put({
      type: USER_LOGIN_S,
      payload: {
        email,
      },
    });
    history.push(action.payload.next);
  } catch (error) {
    yield delay(2000);
    yield put({
      type: USER_LOGIN_F,
      payload: {
        errorMessage: error.message,
      },
    });
    console.log(error); // eslint-disable-line
  }
}

function* restoreUser(action: RestoreUserByTokenRequestedType) {
  try {
    const cognitoSession: CognitoUserSession = yield call(API.verifyUser);
    const { payload: { email } } = cognitoSession.getIdToken();
    yield put({
      type: RESTORE_USER_BY_TOKEN_S,
      payload: {
        email,
      },
    });
  } catch (error) {
    console.log(error.message); // eslint-disable-line
    yield put({
      type: RESTORE_USER_BY_TOKEN_F,
    });
    history.push(`/login?redirect=${action.payload.next}`);
  }
}

function* logout() {
  try {
    yield call(API.logout);
    yield put({
      type: USER_LOGOUT_S,
    });
    history.push('/login');
  } catch (error) {
    console.log(error.message); // eslint-disable-line
    yield put({
      type: USER_LOGOUT_F,
    });
  }
}

export const userSagas = [
  takeEvery(USER_LOGIN_R, login),
  takeEvery(RESTORE_USER_BY_TOKEN_R, restoreUser),
  takeEvery(USER_LOGOUT_R, logout),
];
