import React, { FC } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Button, Drawer, InputNumber, Select } from '@flexboxapps/flbx-webapp-ui';
import { setCreateStockDrawer, setStockInput, createStock } from 'actions/stockActions';
import { RootState } from 'reducers/rootReducer';
import { StockType, StorageType } from 'models';
import './add-stock-drawer.scss';

const AddStockDrawer: FC = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const {
    drawerVisible, selectedProduct: selectedProductId, creatingInProgress, accountingTypes,
    selectedAccountTyping, selectedWarehouse, stockTypes, selectedStockType,
    selectedQuantity,
  } = useSelector(({ stock }: RootState) => stock);

  const { products } = useSelector(({ product }: RootState) => product);
  const { warehouses } = useSelector(({ warehouse }: RootState) => warehouse);

  const selectedProduct = products.find((productItem) => productItem._id === selectedProductId);
  const selectedUnit = selectedStockType === StockType.WHOLE ? selectedProduct?.storageUnit : selectedProduct?.perPieceUnit;

  const disableStorageType = (stockType: StockType): boolean => {
    const selectedStorageType = selectedProduct?.storageType;
    switch (selectedStorageType) {
      case StorageType.WHOLE:
        return stockType !== 'WHOLE';
      case StorageType.MEASURED:
        return stockType !== 'OPENED';
      default:
        return false;
    }
  };

  const parentItems = products
    .filter((optionItem) => !optionItem.hasVariations && !optionItem.variationParent)
    .map((childItem) => ({
      label: childItem.displayName,
      value: childItem._id,
    }));

  const parents = products.filter((parentItem) => parentItem.hasVariations);
  const groupedOptions: any[] = parents.map((parentItem) => ({
    label: parentItem.displayName,
    value: parentItem._id,
    group: products.filter((productItem) => parentItem.root === productItem.variationParent).map((childItem) => ({
      label: childItem.displayName,
      value: childItem._id,
    })),
  }));

  const options = parentItems.concat(groupedOptions);

  return (
    <Drawer
      className="app-drawer drawer-add-category"
      title={t('drawers.addStock.title')}
      onClose={() => dispatch(setCreateStockDrawer('create', false))}
      visible={drawerVisible.create}
    >
      <div className="drawer-inner-container">
        <div className="stock-drawer-input-container">
          <Select
            label={t('drawers.addStock.product').toUpperCase()}
            placeholder={t('drawers.addStock.productPlaceholder')}
            value={selectedProductId || undefined}
            onChange={(value) => { dispatch(setStockInput('selectedProduct', value)); }}
            borderColor="#F2C94C"
            isGroup
            showSearch
            options={options}
          />
          <Select
            label={t('drawers.addStock.stockType').toUpperCase()}
            placeholder={t('drawers.addStock.stockTypePlaceholder')}
            value={selectedStockType || undefined}
            onChange={(value) => { dispatch(setStockInput('selectedStockType', value)); }}
            borderColor="#F2C94C"
            options={stockTypes.map((item) => ({
              label: t(`drawers.addStock.stockTypes.${item.label}`),
              value: item.value,
              disabled: disableStorageType(item.value),
            }))}
          />
          <Select
            label={t('drawers.addStock.warehouse').toUpperCase()}
            placeholder={t('drawers.addStock.warehousePlaceholder')}
            value={selectedWarehouse || undefined}
            onChange={(value) => { dispatch(setStockInput('selectedWarehouse', value)); }}
            borderColor="#F2C94C"
            options={warehouses.length ? warehouses.map((item) => ({
              label: item.title,
              value: item._id,
            })) : []}
            showSearch
          />
          <Select
            label={t('drawers.addStock.typeOfSettlement').toUpperCase()}
            placeholder={t('drawers.addStock.typeOfSettlementPlaceholder')}
            value={selectedAccountTyping || undefined}
            onChange={(value) => { dispatch(setStockInput('selectedAccountTyping', value)); }}
            borderColor="#F2C94C"
            options={accountingTypes.map((item) => ({
              label: t(`drawers.addStock.settlements.${item.label}`),
              value: item.value,
            }))}
          />
          <InputNumber
            label={t('drawers.addStock.startingQuantity').toUpperCase()}
            value={selectedQuantity || 0}
            onChange={(value) => { dispatch(setStockInput('selectedQuantity', value)); }}
            min={0}
            max={10000}
            step={1}
            prefix={selectedUnit?.shortName}
            borderColor="#F2C94C"
          />
        </div>
      </div>
      <div className="drawer-actions">
        <Button
          label={t('app.common.buttons.cancel')}
          disabled={creatingInProgress}
          onClick={() => dispatch(setCreateStockDrawer('create', false))}
          type="ghost"
        />
        <Button
          label={t('app.common.buttons.create')}
          type="secondary"
          loading={creatingInProgress}
          onClick={() => { dispatch(createStock()); }}
        />
      </div>
    </Drawer>
  );
};

export default AddStockDrawer;
