import { StorageType } from 'models';
import { Unit } from 'reducers/units/initialState';

export type Product = {
  displayName: string;
  storageType?: StorageType;
  storageUnit?: Unit;
  storageQuantity?: number;
  perPieceUnit?: Unit;
  perPieceQuantity?: number;
  defaultQuantity?: number;
  hasVariations?: boolean;
  variationParent?: string;
  userId: string;
  createdAt: string;
  deprecated: boolean;
  updatedAt: string;
  parent: null;
  root: null;
  __v: number;
  _id: string;
};

export type ProductState = {
  products: Product[];
  drawerVisible: {
    create: boolean,
    update: boolean,
  },
  creatingInProgress: boolean;
  updatingInProgress: boolean;
  displayName: string;
  storageTypes: Array<{ label: string, value: StorageType }>;
  storageType?: StorageType;
  storageUnit?: string;
  storageQuantity?: number;
  perPieceUnit?: string;
  perPieceQuantity?: number;
  defaultQuantity?: number;
  hasVariations?: boolean;
  variationParents: Array<{ label: string, value: string }>;
  variationParent?: string;
  variations: Array<string>;
};

export const initialState: ProductState = {
  products: [],
  drawerVisible: {
    create: false,
    update: false,
  },
  creatingInProgress: false,
  updatingInProgress: false,
  displayName: '',
  storageTypes: [
    { label: StorageType.WHOLE, value: StorageType.WHOLE },
    { label: StorageType.UNFOLDABLE, value: StorageType.UNFOLDABLE },
    { label: StorageType.MEASURED, value: StorageType.MEASURED },
  ],
  storageType: undefined,
  storageUnit: undefined,
  storageQuantity: 1,
  perPieceUnit: undefined,
  perPieceQuantity: 1,
  defaultQuantity: 1,
  hasVariations: undefined,
  variationParents: [],
  variationParent: undefined,
  variations: [],
};
