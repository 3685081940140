import React, { FC, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Button, Drawer, Input, Select } from '@flexboxapps/flbx-webapp-ui';
import { setCreateWarehouseDrawer, updateWarehouse } from 'actions/warehouseActions';
import { RootState } from 'reducers/rootReducer';
import { Location } from 'reducers/locations/initialState';
import { Warehouse } from 'reducers/warehouses/initialState';
import { SelectValue } from 'antd/lib/select';
import './add-warehouse-drawer.scss';

export interface UpdateWarehouseDrawerProps {
  selectedWarehouse: Warehouse;
}

const UpdateWarehouseDrawer: FC<UpdateWarehouseDrawerProps> = ({ selectedWarehouse }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [data, setData] = useState<Warehouse | null>(null);

  useEffect(() => {
    setData(selectedWarehouse);
  }, [selectedWarehouse]);

  const handleDataChange = (key: string, value: string | SelectValue) => {
    if (data) {
      setData({
        ...data,
        [key]: value,
      });
    }
  };

  const {
    drawerVisible, updatingInProgress, warehouseTypes,
  } = useSelector(({ warehouse }: RootState) => warehouse);

  const {
    locations,
  } = useSelector(({ location }: RootState) => location);

  return data ? (
    <Drawer
      className="app-drawer drawer-add-category"
      title={t('drawers.addWarehouse.updateTitle')}
      onClose={() => dispatch(setCreateWarehouseDrawer('update', false))}
      visible={drawerVisible.update}
    >
      <div className="drawer-inner-container">
        <div className="warehouse-drawer-input-container">
          <Input
            label={t('drawers.addWarehouse.warehouseName').toUpperCase()}
            placeholder={t('drawers.addWarehouse.warehouseNamePlaceholder')}
            value={data.title}
            onChange={(event) => { handleDataChange('title', event.target.value); }}
            borderColor="#F2C94C"
          />
          <Select
            label={t('drawers.addWarehouse.warehouseLocation').toUpperCase()}
            placeholder={t('drawers.addWarehouse.warehouseLocationPlaceholder')}
            value={data.location}
            options={locations.map((item: Location) => ({
              label: item.title,
              value: item._id,
            }))}
            onChange={(value) => { handleDataChange('location', value); }}
            borderColor="#F2C94C"
            size="large"
          />
          <Select
            label={t('drawers.addWarehouse.warehouseType').toUpperCase()}
            placeholder={t('drawers.addWarehouse.warehouseTypePlaceholder')}
            value={data.type}
            options={warehouseTypes.map((item) => ({
              label: t(`drawers.addWarehouse.type.${item.label}`),
              value: item.value,
            }))}
            onChange={(value) => { handleDataChange('type', value); }}
            borderColor="#F2C94C"
            size="large"
          />
        </div>
      </div>
      <div className="drawer-actions">
        <Button
          label={t('app.common.buttons.cancel')}
          disabled={updatingInProgress}
          onClick={() => dispatch(setCreateWarehouseDrawer('update', false))}
          type="ghost"
        />
        <Button
          label={t('app.common.buttons.update')}
          type="secondary"
          disabled={data.title === '' || !data.type || !data.location}
          loading={updatingInProgress}
          onClick={() => { dispatch(updateWarehouse(data)); }}
        />
      </div>
    </Drawer>
  ) : null;
};

export default UpdateWarehouseDrawer;
