export type Unit = {
  titles: {
    hu: string;
    en: string;
    [lang: string]: string;
  },
  _id: string;
  shortName: string;
  category: string;
};

export type UnitState = {
  units: Unit[],
  unitCategories: string[],
};

export const initialState: UnitState = {
  units: [],
  unitCategories: [],
};
