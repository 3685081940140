import { takeEvery, call, put, select } from 'redux-saga/effects';
import {
  GET_STOCK_R, GET_STOCK_F, GET_STOCK_S, StockGetStockRequested,
  CREATE_STOCK_R, CREATE_STOCK_F, CREATE_STOCK_S,
  UPDATE_STOCK_R, UPDATE_STOCK_F, UPDATE_STOCK_S, StockUpdateStockRequested,
} from 'consts/stockActionTypes';
import { Sorts } from '@flexboxapps/flbx-webapp-ui';
import * as API from 'services/api';
import { RootState } from 'reducers/rootReducer';
import { StockType } from 'models';

const getStockStore = (state: RootState) => state.stock;

function* getStocks(action: StockGetStockRequested) {
  try {
    const { value, key } = action.payload;
    const query = {
      $params: JSON.stringify({
        deprecated: false,
      }),
      $sort: JSON.stringify({
        [key || 'createdAt']: value || Sorts.asc,
      }),
    };
    const data: [any] = yield call(API.findDataService, 'stock', '/stock', query);
    yield put({
      type: GET_STOCK_S,
      payload: {
        stocks: data,
      },
    });
  } catch (error) {
    yield put({
      type: GET_STOCK_F,
      payload: {
        errorMessage: error.message,
      },
    });
    console.log(error); // eslint-disable-line
  }
}

function* createStock() {
  try {
    const {
      selectedProduct, selectedWarehouse,
      selectedAccountTyping, selectedQuantity,
      selectedStockType,
    } = yield select(getStockStore);
    const updatedData = {
      product: selectedProduct,
      warehouse: selectedWarehouse,
      accountingType: selectedAccountTyping,
      quantity: selectedQuantity,
      unfolded: selectedStockType !== StockType.WHOLE,
    };
    yield call(API.createDataService, 'stock', '/stock', updatedData);
    yield put({
      type: CREATE_STOCK_S,
    });
    yield put({
      type: GET_STOCK_R,
      payload: {
        value: Sorts.asc,
        key: 'createdAt',
        term: '',
      },
    });
  } catch (error) {
    yield put({
      type: CREATE_STOCK_F,
      payload: {
        errorMessage: error.message,
      },
    });
    console.log(error); // eslint-disable-line
  }
}

function* updateStock(action: StockUpdateStockRequested) {
  try {
    const { data } = action.payload;
    const updatedData = {
      product: data.product,
      warehouse: data.warehouse,
      accountingType: data.accountingType,
      quantity: data.quantity,
      unfolded: data.selectedStockType !== StockType.WHOLE,
    };
    yield call(API.patchDataService, 'stock', '/stock', data._id, updatedData);
    yield put({
      type: UPDATE_STOCK_S,
    });
    yield put({
      type: GET_STOCK_R,
      payload: {
        value: Sorts.asc,
        key: 'createdAt',
        term: '',
      },
    });
  } catch (error) {
    yield put({
      type: UPDATE_STOCK_F,
      payload: {
        errorMessage: error.message,
      },
    });
    console.log(error); // eslint-disable-line
  }
}

export const stockSagas = [
  takeEvery(GET_STOCK_R, getStocks),
  takeEvery(CREATE_STOCK_R, createStock),
  takeEvery(UPDATE_STOCK_R, updateStock),
];
