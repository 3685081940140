import {
  APP_CHANGE_LANGUAGE, AppChangeLanguageType,
} from 'consts/appActionTypes';

export const changeLanguage = (lang: string): AppChangeLanguageType => ({
  type: APP_CHANGE_LANGUAGE,
  payload: {
    lang,
  },
});
