export type UserState = {
  isAuthenticating: boolean,
  isAuthenticated: boolean,
  hasAuthenticatingError: boolean,
  errorMessage: string,
  email?: string,
};

export const initialState: UserState = {
  isAuthenticating: false,
  isAuthenticated: false,
  hasAuthenticatingError: false,
  errorMessage: '',
  email: undefined,
};
