import React, { FC } from 'react';
import { Switch, Redirect } from 'react-router-dom';
import ROUTES from 'routes';
import AppRoute from 'components/App/AppRoute';
import PublicRoute from 'components/App/PublicRoute';
import LoginLayout from 'layouts/LoginLayout/LoginLayout';

const App: FC = () => (
  <Switch>
    <Redirect exact from="/" to="/dashboard" />
    <PublicRoute exact path="/login" component={ROUTES.LoginPage} layout={LoginLayout} />
    <AppRoute exact path="/dashboard" component={ROUTES.DashboardPage} />
    <AppRoute exact path="/locations" component={ROUTES.LocationPage} />
    <AppRoute exact path="/warehouses" component={ROUTES.WarehousePage} />
    <AppRoute exact path="/products" component={ROUTES.ProductPage} />
    <AppRoute exact path="/stocks" component={ROUTES.StockPage} />
  </Switch>
);

export default App;
