import {
  ProductActionTypes,
  GET_PRODUCT_S,
  SET_PRODUCT_DRAWER,
  SET_PRODUCT_INPUT,
  CREATE_PRODUCT_R,
  CREATE_PRODUCT_S,
  CREATE_PRODUCT_F,
  UPDATE_PRODUCT_R,
  UPDATE_PRODUCT_S,
  UPDATE_PRODUCT_F,
} from 'consts/productActionTypes';
import { initialState, ProductState } from './initialState';

const productReducer = (state = initialState, action: ProductActionTypes): ProductState => {
  switch (action.type) {
    case GET_PRODUCT_S: {
      const { products } = action.payload;
      return {
        ...state,
        products,
      };
    }

    case SET_PRODUCT_DRAWER: {
      const { key, value } = action.payload;
      return {
        ...initialState,
        products: state.products,
        drawerVisible: {
          ...state.drawerVisible,
          [key]: value,
        },
      };
    }

    case SET_PRODUCT_INPUT: {
      const { key, value } = action.payload;
      return {
        ...state,
        [key]: value,
      };
    }

    case CREATE_PRODUCT_R:
      return {
        ...state,
        creatingInProgress: true,
      };

    case CREATE_PRODUCT_S:
      return {
        ...state,
        creatingInProgress: false,
        drawerVisible: {
          ...state.drawerVisible,
          create: false,
        },
      };

    case CREATE_PRODUCT_F:
      return {
        ...state,
        creatingInProgress: false,
      };

    case UPDATE_PRODUCT_R:
      return {
        ...state,
        updatingInProgress: true,
      };

    case UPDATE_PRODUCT_S:
      return {
        ...state,
        updatingInProgress: false,
        drawerVisible: {
          ...state.drawerVisible,
          update: false,
        },
      };

    case UPDATE_PRODUCT_F:
      return {
        ...state,
        updatingInProgress: false,
      };

    default:
      return state;
  }
};

export default productReducer;
