// LOGIN
export const USER_LOGIN_F = 'USER_LOGIN_F';
export interface UserLoginFailedType {
  type: typeof USER_LOGIN_F,
  payload: { errorMessage: string },
}

export const USER_LOGIN_R = 'USER_LOGIN_R';
export interface UserLoginRequestedType {
  type: typeof USER_LOGIN_R,
  payload: { email: string, password: string, next: string },
}

export const USER_LOGIN_S = 'USER_LOGIN_S';
export interface UserLoginSucceededType {
  type: typeof USER_LOGIN_S,
  payload: { email: string },
}

// LOGOUT
export const USER_LOGOUT_F = 'USER_LOGOUT_F';

export const USER_LOGOUT_R = 'USER_LOGOUT_R';
export interface UserLogoutRequestedType {
  type: typeof USER_LOGOUT_R,
}

export const USER_LOGOUT_S = 'USER_LOGOUTS';
export interface UserLogoutSucceededType {
  type: typeof USER_LOGOUT_S,
}

// RESTORE USER
export const RESTORE_USER_BY_TOKEN_F = 'RESTORE_USER_BY_TOKEN_F';

export const RESTORE_USER_BY_TOKEN_R = 'RESTORE_USER_BY_TOKEN_R';
export interface RestoreUserByTokenRequestedType {
  type: typeof RESTORE_USER_BY_TOKEN_R,
  payload: { next?: string },
}

export const RESTORE_USER_BY_TOKEN_S = 'RESTORE_USER_BY_TOKEN_S';
export interface RestoreUserByTokenSucceededType {
  type: typeof RESTORE_USER_BY_TOKEN_S,
  payload: { email: string },
}

export type UserActionTypes = UserLoginRequestedType | UserLogoutRequestedType
| RestoreUserByTokenRequestedType | UserLoginSucceededType
| RestoreUserByTokenSucceededType | UserLoginFailedType
| UserLogoutSucceededType;
