export type Address = {
  address: string;
  city: string;
  country: string;
  postalCode: string;
  state: string;
};

export type Location = {
  address: Address;
  createdAt: string;
  deprecated: boolean;
  parent: null;
  root: null;
  title: string;
  updatedAt: string;
  userId: string;
  value: number;
  __v: number;
  _id: string;
};

export type LocationState = {
  locations: Location[],
  drawerVisible: {
    create: boolean;
    update: boolean;
  },
  title: string;
  creatingInProgress: boolean;
  updatingInProgress: boolean;
};

export const initialState: LocationState = {
  locations: [],
  drawerVisible: {
    create: false,
    update: false,
  },
  title: '',
  creatingInProgress: false,
  updatingInProgress: false,
};
