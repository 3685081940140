import React from 'react';
import ReactDOM from 'react-dom';
import Root from 'components/App/Root';
import { store } from 'store';
import { history } from 'services/config';
import reportWebVitals from './reportWebVitals';
import 'locales/i18n';
import '@flexboxapps/flbx-webapp-ui/dist/index.cjs.css';
import './index.scss';

ReactDOM.render(
  <React.StrictMode>
    <Root store={store} history={history} />
  </React.StrictMode>,
  document.getElementById('root'),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
