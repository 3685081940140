import React, { FC } from 'react';
import { useClearCache } from 'react-clear-cache';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faRocket } from '@fortawesome/pro-duotone-svg-icons';
import './update-app.scss';

const UpdateApp: FC = () => {
  const { t } = useTranslation();
  const { emptyCacheStorage } = useClearCache();

  return (
    <div className="update-app">
      <div className="update-app-container">
        <div className="update-app-icon">
          <FontAwesomeIcon key="fa-rocket" icon={faRocket} color="#764BA2" size="6x" />
        </div>
        <div className="update-app-text">{t('pages.update.availableUpdate')}</div>
        <div
          className="update-app-button"
          role="button"
          tabIndex={0}
          onClick={() => {
            emptyCacheStorage();
          }}
        >
          {t('pages.update.updateApp')}
        </div>
      </div>
    </div>
  );
};

export default UpdateApp;
