import React, { FC, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Button, Drawer, InputNumber, Select, TreeSelect } from '@flexboxapps/flbx-webapp-ui';
import { setCreateStockDrawer, updateStock } from 'actions/stockActions';
import { RootState } from 'reducers/rootReducer';
import { StockType, StorageType } from 'models';
import { Stock } from 'reducers/stocks/initialState';
import './add-stock-drawer.scss';
import { SelectValue } from 'antd/lib/select';

export interface UpdateStockDrawerProps {
  selectedStock: Stock,
}

const UpdateStockDrawer: FC<UpdateStockDrawerProps> = ({ selectedStock }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { products } = useSelector(({ product }: RootState) => product);
  const { warehouses } = useSelector(({ warehouse }: RootState) => warehouse);
  const [data, setData] = useState<Stock | null>(null);

  useEffect(() => {
    setData(selectedStock);
  }, [selectedStock, setData]);

  const handleDataChange = (key: string, value?: string | number | SelectValue) => {
    if (data) {
      setData({
        ...data,
        [key]: value,
      });
    }
  };

  const {
    drawerVisible,
    updatingInProgress,
    accountingTypes,
    stockTypes,
  } = useSelector(({ stock }: RootState) => stock);

  const treeProducts = products.length ? products
    .map((originalItem, _, array) => ({
      ...originalItem,
      selectable: !originalItem.hasVariations,
      children: originalItem.hasVariations
        ? array
          .filter((arrayItem) => arrayItem.variationParent === originalItem._id)
          .map((filteredItem) => ({
            title: filteredItem.displayName,
            value: filteredItem._id,
          }))
        : [],
    }))
    .filter((itemWithChildren) => !itemWithChildren.variationParent)
    .map((mappedItem) => ({
      title: mappedItem.displayName,
      value: mappedItem._id,
      children: mappedItem.children,
      selectable: mappedItem.selectable,
    })) : [];

  const selectedStockType = data?.unfolded ? StockType.OPENED : StockType.WHOLE;
  const selectedProduct = products.find((productItem) => productItem._id === data?.product);
  const selectedUnit = selectedStockType === StockType.WHOLE ? selectedProduct?.storageUnit : selectedProduct?.perPieceUnit;

  const disableStorageType = (stockType: StockType): boolean => {
    const selectedStorageType = selectedProduct?.storageType;
    switch (selectedStorageType) {
      case StorageType.WHOLE:
        return stockType !== 'WHOLE';
      case StorageType.MEASURED:
        return stockType !== 'OPENED';
      default:
        return false;
    }
  };

  return data ? (
    <Drawer
      className="app-drawer drawer-add-category"
      title={t('drawers.addStock.updateTitle')}
      onClose={() => dispatch(setCreateStockDrawer('update', false))}
      visible={drawerVisible.update}
    >
      <div className="drawer-inner-container">
        <div className="stock-drawer-input-container">
          <TreeSelect
            label={t('drawers.addStock.product').toUpperCase()}
            placeholder={t('drawers.addStock.productPlaceholder')}
            value={data.product || undefined}
            onChange={(value) => { handleDataChange('product', value); }}
            borderColor="#F2C94C"
            treeData={treeProducts}
          />
          <Select
            label={t('drawers.addStock.stockType').toUpperCase()}
            placeholder={t('drawers.addStock.stockTypePlaceholder')}
            value={selectedStockType || undefined}
            onChange={(value) => { handleDataChange('selectedStockType', value); }}
            borderColor="#F2C94C"
            options={stockTypes.map((item) => ({
              label: t(`drawers.addStock.stockTypes.${item.label}`),
              value: item.value,
              disabled: disableStorageType(item.value),
            }))}
          />
          <Select
            label={t('drawers.addStock.warehouse').toUpperCase()}
            placeholder={t('drawers.addStock.warehousePlaceholder')}
            value={data.warehouse || undefined}
            onChange={(value) => { handleDataChange('warehouse', value); }}
            borderColor="#F2C94C"
            options={warehouses.length ? warehouses.map((item) => ({
              label: item.title,
              value: item._id,
            })) : []}
          />
          <Select
            label={t('drawers.addStock.typeOfSettlement').toUpperCase()}
            placeholder={t('drawers.addStock.typeOfSettlementPlaceholder')}
            value={data.accountingType || undefined}
            onChange={(value) => { handleDataChange('accountingType', value); }}
            borderColor="#F2C94C"
            options={accountingTypes.map((item) => ({
              label: t(`drawers.addStock.settlements.${item.label}`),
              value: item.value,
            }))}
          />
          <InputNumber
            label={t('drawers.addStock.startingQuantity').toUpperCase()}
            value={data.quantity || 0}
            onChange={(value) => { handleDataChange('quantity', value); }}
            min={0}
            max={10000}
            step={1}
            prefix={selectedUnit?.shortName}
            borderColor="#F2C94C"
          />
        </div>
      </div>
      <div className="drawer-actions">
        <Button
          label={t('app.common.buttons.cancel')}
          disabled={updatingInProgress}
          onClick={() => dispatch(setCreateStockDrawer('update', false))}
          type="ghost"
        />
        <Button
          label={t('app.common.buttons.update')}
          type="secondary"
          loading={updatingInProgress}
          onClick={() => { dispatch(updateStock(data)); }}
        />
      </div>
    </Drawer>
  ) : null;
};

export default UpdateStockDrawer;
