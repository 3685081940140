import React, { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Table } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPencil } from '@fortawesome/pro-solid-svg-icons';
import { Header, BottomHeader } from '@flexboxapps/flbx-webapp-ui';
import { RootState } from 'reducers/rootReducer';
import { logout } from 'actions/userActions';
import { getStocks, setCreateStockDrawer } from 'actions/stockActions';
import { getProducts } from 'actions/productActionts';
import { getWarehouses } from 'actions/warehouseActions';
import { getUnits } from 'actions/unitActions';
import { history } from 'services/config';
import { showNotImplementedAlert } from 'components/NotImplemented/showNotImplementedAlert';
import AddStockDrawer from 'components/Stock/AddStockDrawer';
import UpdateStockDrawer from 'components/Stock/UpdateStockDrawer';
import useResize from 'services/useWindowResize';
import { Stock } from 'reducers/stocks/initialState';
import { ReactComponent as StocksIcon } from 'assets/stocks.svg';
import './stock-page.scss';

const StockPage: FC = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { width } = useResize();
  const { email } = useSelector(({ user }: RootState) => user);
  const { products } = useSelector(({ product }: RootState) => product);
  const { warehouses } = useSelector(({ warehouse }: RootState) => warehouse);
  const { stocks } = useSelector(({ stock }: RootState) => stock);
  const { units } = useSelector(({ unit }: RootState) => unit);
  const [selectedStock, setStock] = useState(stocks[0]);

  useEffect(() => {
    dispatch(getStocks(null, '', ''));
    if (!products.length) {
      dispatch(getProducts());
    }
    if (!warehouses.length) {
      dispatch(getWarehouses());
    }
    if (!units.length) {
      dispatch(getUnits());
    }
  }, []);

  const columns: any = [
    {
      title: t('pages.stocks.columns.productName'),
      dataIndex: 'productName',
      key: 'productName',
      width: width && width < 568 ? 180 : 300,
      sorter: (a: any, b: any) => (a.productName > b.productName ? 1 : -1),
    },
    {
      title: t('pages.stocks.columns.quantity'),
      dataIndex: 'quantity',
      key: 'quantity',
      align: 'center',
      sorter: (a: any, b: any) => (a.quantity - b.quantity),
    },
    {
      title: t('pages.stocks.columns.warehouse'),
      dataIndex: 'warehouse',
      key: 'warehouse',
      align: 'center',
      sorter: (a: any, b: any) => (a.warehouse > b.warehouse ? 1 : -1),
    },
    {
      title: '',
      dataIndex: '',
      key: '',
      render: (item: { wholeStock: Stock }) => (
        <FontAwesomeIcon
          icon={faPencil}
          onClick={() => {
            dispatch(setCreateStockDrawer('update', true));
            setStock(item.wholeStock);
          }}
        />
      ),
    },
  ];

  const stockTableData = products.length && warehouses.length && stocks.length ? stocks.map((item) => ({
    wholeStock: item,
    productName: products.find((productItem) => productItem._id === item.product || productItem.root === item.product)?.displayName,
    quantity: item.quantity,
    warehouse: warehouses.find((warehouseItem) => warehouseItem._id === item.warehouse || warehouseItem.root === item.warehouse)?.title,
  })) : [];

  const usermenu = [{ label: t('app.common.buttons.logout'), action: () => dispatch(logout()) }];

  return (
    <div className="page page-stock">
      <Header
        userinfo={email || ''}
        onHamburgerMenuClick={showNotImplementedAlert}
        onLogoClick={() => { history.push('/dashboard'); }}
        usermenu={usermenu}
        large
        inverse
      />
      <div className="stock-page-body">
        <AddStockDrawer />
        <UpdateStockDrawer selectedStock={selectedStock} />
        <BottomHeader
          title={t('pages.stocks.title').toUpperCase()}
          subtitle={t('pages.stocks.subtitle')}
          buttonTitle={t('pages.stocks.buttonTitle')}
          breadcrumbs={[{ title: 'Dashboard', link: '/dashboard' }]}
          onClick={() => { dispatch(setCreateStockDrawer('create', true)); }}
          icon={<StocksIcon />}
          inverse
        />
        <div className="stocks-search-container">
          <Table
            className="stock-table"
            columns={columns}
            dataSource={stockTableData}
            loading={!products.length || !warehouses.length}
            showSorterTooltip={false}
            locale={{ emptyText: t('app.common.noData') }}
            pagination={false}
            scroll={{ x: 624 }}
          />
        </div>
      </div>
    </div>
  );
};

export default StockPage;
