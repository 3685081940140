import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFingerprint } from '@fortawesome/pro-regular-svg-icons';
import Login from 'components/Login/Login';
import { ReactComponent as LogoWideInverse } from 'assets/logo-wide-inverse.svg';
import { ReactComponent as LogoWide } from 'assets/logo-wide-normal.svg';
import './login-page.scss';

const LoginPage: FC = () => {
  const { t } = useTranslation();
  return (
    <div className="page page-login">
      <div className="logo">
        <LogoWide className="logo-normal" />
        <LogoWideInverse width="196" className="logo-inverse" />
      </div>
      <div className="login-background">
        <div className="login-box">
          <div className="header-container">
            <FontAwesomeIcon icon={faFingerprint} />
            <div>
              <div className="form-title">{t('pages.login.title')}</div>
              <div className="form-subtitle">{t('pages.login.subtitle')}</div>
            </div>
          </div>
          <Login />
        </div>
      </div>

    </div>
  );
};

export default LoginPage;
