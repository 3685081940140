import { combineReducers } from 'redux';
import app from 'reducers/app/appReducer';
import user from 'reducers/user/userReducer';
import location from 'reducers/locations/locationReducer';
import warehouse from 'reducers/warehouses/warehouseReducer';
import product from 'reducers/products/productReducer';
import unit from 'reducers/units/unitReducer';
import stock from 'reducers/stocks/stockReducer';

const rootReducer = combineReducers({
  app,
  user,
  location,
  warehouse,
  product,
  unit,
  stock,
});

export type RootState = ReturnType<typeof rootReducer>;

export default rootReducer;
