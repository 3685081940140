import { AccountingType, StockType } from 'models';

export type Stock = {
  accountingType: string;
  createdAt: string;
  deprecated: boolean;
  parent: null;
  product: string;
  quantity: number;
  root: string;
  unfolded: boolean;
  updatedAt: string;
  userId: string;
  warehouse: string;
  __v: number;
  _id: string;
};

export type StockState = {
  stocks: Stock[],
  accountingTypes: Array<{ label: string, value: AccountingType }>;
  stockTypes: Array<{ label: string, value: StockType }>;
  drawerVisible: {
    create: boolean;
    update: boolean;
  },
  selectedProduct: string;
  selectedWarehouse: string;
  selectedAccountTyping: string;
  selectedStockType: string;
  selectedQuantity: number;
  creatingInProgress: boolean;
  updatingInProgress: boolean;
};

export const initialState: StockState = {
  stocks: [],
  drawerVisible: {
    create: false,
    update: false,
  },
  selectedProduct: '',
  selectedWarehouse: '',
  selectedAccountTyping: '',
  selectedStockType: '',
  creatingInProgress: false,
  updatingInProgress: false,
  selectedQuantity: 0,
  accountingTypes: [
    {
      label: AccountingType.INSTANTLY,
      value: AccountingType.INSTANTLY,
    },
    {
      label: AccountingType.MANUALLY,
      value: AccountingType.MANUALLY,
    },
    {
      label: AccountingType.DAILY,
      value: AccountingType.DAILY,
    },
  ],
  stockTypes: [
    {
      label: StockType.WHOLE,
      value: StockType.WHOLE,
    },
    {
      label: StockType.OPENED,
      value: StockType.OPENED,
    },
  ],
};
