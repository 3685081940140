import { Sorts } from '@flexboxapps/flbx-webapp-ui';
import { SelectValue } from 'antd/lib/select';

// STOCK ACTIONS
export const GET_STOCK_R = 'GET_STOCK_R';
export interface StockGetStockRequested {
  type: typeof GET_STOCK_R,
  payload: {
    value?: Sorts | null,
    key?: string,
    term?: string,
  },
}

export const GET_STOCK_S = 'GET_STOCK_S';
export interface StockGetStockSucceeded {
  type: typeof GET_STOCK_S,
  payload: {
    stocks: [],
  },
}

export const GET_STOCK_F = 'GET_STOCK_F';

export const SET_STOCK_INPUT = 'SET_STOCK_INPUT';

export interface StockSetInput {
  type: typeof SET_STOCK_INPUT,
  payload: {
    key: string,
    value?: string | number | boolean | SelectValue | string[],
  },
}

export const SET_STOCK_DRAWER = 'SET_STOCK_DRAWER';

export interface StockSetDrawer {
  type: typeof SET_STOCK_DRAWER,
  payload: {
    key: string,
    value: boolean,
  },
}

export const CREATE_STOCK_R = 'CREATE_STOCK_R';
export interface StockCreateStockRequested {
  type: typeof CREATE_STOCK_R,
}

export const CREATE_STOCK_S = 'CREATE_STOCK_S';
export interface StockCreateStockSucceeded {
  type: typeof CREATE_STOCK_S,
}

export const CREATE_STOCK_F = 'CREATE_STOCK_F';
export interface StockCreateStockFailed {
  type: typeof CREATE_STOCK_F,
}

export const UPDATE_STOCK_R = 'UPDATE_STOCK_R';
export interface StockUpdateStockRequested {
  type: typeof UPDATE_STOCK_R,
  payload: {
    data: any,
  },
}

export const UPDATE_STOCK_S = 'UPDATE_STOCK_S';
export interface StockUpdateStockSucceeded {
  type: typeof UPDATE_STOCK_S,
}

export const UPDATE_STOCK_F = 'UPDATE_STOCK_F';
export interface StockUpdateStockFailed {
  type: typeof UPDATE_STOCK_F,
}

// ACTIONTYPES
export type StockActionTypes =
| StockGetStockRequested
| StockGetStockSucceeded
| StockSetInput
| StockSetDrawer
| StockCreateStockRequested
| StockCreateStockSucceeded
| StockCreateStockFailed
| StockUpdateStockRequested
| StockUpdateStockSucceeded
| StockUpdateStockFailed;
