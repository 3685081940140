export type Warehouse = {
  createdAt: string;
  deprecated: boolean;
  parent: null;
  root: null;
  title: string;
  location: string;
  type: string;
  updatedAt: string;
  userId: string;
  value: number;
  __v: number;
  _id: string;
};

export enum WarehouseType {
  MATERIAL = 'MATERIAL',
  PRODUCT = 'PRODUCT',
}

export type WarehouseState = {
  warehouses: Warehouse[],
  drawerVisible: {
    create: boolean,
    update: boolean,
  },
  title: string;
  location: string | undefined;
  type: WarehouseType | undefined;
  warehouseTypes: Array<{ label: string, value: WarehouseType }>;
  creatingInProgress: boolean;
  updatingInProgress: boolean;
};

export const initialState: WarehouseState = {
  warehouses: [],
  drawerVisible: {
    create: false,
    update: false,
  },
  warehouseTypes: [
    { label: WarehouseType.MATERIAL, value: WarehouseType.MATERIAL },
    { label: WarehouseType.PRODUCT, value: WarehouseType.PRODUCT },
  ],
  title: '',
  type: undefined,
  location: undefined,
  creatingInProgress: false,
  updatingInProgress: false,
};
