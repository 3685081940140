import { takeEvery, call, put } from 'redux-saga/effects';
import {
  GET_UNIT_R, GET_UNIT_F, GET_UNIT_S,
} from 'consts/unitActionTypes';
import * as API from 'services/api';

function* getUnits() {
  try {
    const query = {
      $params: JSON.stringify({
        deprecated: false,
      }),
    };
    const data: [any] = yield call(API.findDataService, 'units', '/units', query);
    yield put({
      type: GET_UNIT_S,
      payload: {
        units: data,
      },
    });
  } catch (error) {
    yield put({
      type: GET_UNIT_F,
      payload: {
        errorMessage: error.message,
      },
    });
    console.log(error); // eslint-disable-line
  }
}

export const unitSagas = [
  takeEvery(GET_UNIT_R, getUnits),
];
