import {
  USER_LOGIN_R, UserLoginRequestedType,
  RESTORE_USER_BY_TOKEN_R, RestoreUserByTokenRequestedType,
  USER_LOGOUT_R, UserLogoutRequestedType,
} from 'consts/userActionTypes';

export const login = (email: string, password: string, next: string): UserLoginRequestedType => ({
  type: USER_LOGIN_R,
  payload: {
    email,
    password,
    next,
  },
});

export const restoreUserByToken = (next?: string): RestoreUserByTokenRequestedType => ({
  type: RESTORE_USER_BY_TOKEN_R,
  payload: {
    next,
  },
});

export const logout = (): UserLogoutRequestedType => ({
  type: USER_LOGOUT_R,
});
