import 'core-js/stable';
import 'regenerator-runtime/runtime';
import { createStore, compose, applyMiddleware } from 'redux';
import reduxImmutableStateInvariant from 'redux-immutable-state-invariant';
import createSagaMiddleware from 'redux-saga';
import rootReducer, { RootState } from 'reducers/rootReducer';
import rootSaga from 'sagas/rootSaga';
import { ActionTypes } from 'consts/actionTypes';
import { USER_LOGOUT_S } from 'consts/userActionTypes';

declare global {
  interface Window {
    __REDUX_DEVTOOLS_EXTENSION_COMPOSE__?: typeof compose;
  }
}

const reducers = (state: RootState | undefined, action: ActionTypes) => {
  if (action.type === USER_LOGOUT_S) {
    state = undefined; // eslint-disable-line no-param-reassign
  }
  return rootReducer(state, action);
};

// PROD
function configureStoreProd(initialState?: any) {
  const sagaMiddleware = createSagaMiddleware();

  const middlewares = [
    sagaMiddleware,
  ];

  const store = createStore(reducers, initialState, compose(
    applyMiddleware(...middlewares),
  ));
  sagaMiddleware.run(rootSaga);

  return store;
}

// DEV
function configureStoreDev(initialState?: any) {
  const sagaMiddleware = createSagaMiddleware();

  const middlewares = [
    reduxImmutableStateInvariant(),
    sagaMiddleware,
  ];

  const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
  const store = createStore(reducers, initialState, composeEnhancers(
    applyMiddleware(...middlewares),
  ));

  if (module.hot) {
    module.hot.accept('./reducers/rootReducer', () => {
      const nextReducer = require('./reducers/rootReducer').default; // eslint-disable-line
      store.replaceReducer(nextReducer);
    });
  }

  sagaMiddleware.run(rootSaga);

  return store;
}

const configureStore = process.env.NODE_ENV === 'production' ? configureStoreProd : configureStoreDev;

export const store = configureStore();
