import { Sorts } from '@flexboxapps/flbx-webapp-ui';
import { SelectValue } from 'antd/lib/select';
import { Warehouse } from 'reducers/warehouses/initialState';

// WAREHOUSE ACTIONS
export const GET_WAREHOUSE_R = 'GET_WAREHOUSE_R';
export interface WarehouseGetWarehouseRequested {
  type: typeof GET_WAREHOUSE_R,
  payload: {
    value?: Sorts | null,
    key?: string,
    term?: string,
  },
}

export const GET_WAREHOUSE_S = 'GET_WAREHOUSE_S';
export interface WarehouseGetWarehouseSucceeded {
  type: typeof GET_WAREHOUSE_S,
  payload: {
    warehouses: Warehouse[],
  },
}

export const GET_WAREHOUSE_F = 'GET_WAREHOUSE_F';

export const SET_WAREHOUSE_INPUT = 'SET_WAREHOUSE_INPUT';

export interface WarehouseSetInput {
  type: typeof SET_WAREHOUSE_INPUT,
  payload: {
    key: string,
    value?: string | number | boolean | SelectValue,
  },
}

export const SET_WAREHOUSE_DRAWER = 'SET_WAREHOUSE_DRAWER';

export interface WarehouseSetDrawer {
  type: typeof SET_WAREHOUSE_DRAWER,
  payload: {
    key: string,
    value: boolean,
  },
}

export const CREATE_WAREHOUSE_R = 'CREATE_WAREHOUSE_R';
export interface WarehouseCreateWarehouseRequested {
  type: typeof CREATE_WAREHOUSE_R,
}

export const CREATE_WAREHOUSE_S = 'CREATE_WAREHOUSE_S';
export interface WarehouseCreateWarehouseSucceeded {
  type: typeof CREATE_WAREHOUSE_S,
}

export const CREATE_WAREHOUSE_F = 'CREATE_WAREHOUSE_F';
export interface WarehouseCreateWarehouseFailed {
  type: typeof CREATE_WAREHOUSE_F,
}

export const UPDATE_WAREHOUSE_R = 'UPDATE_WAREHOUSE_R';
export interface WarehouseUpdateWarehouseRequested {
  type: typeof UPDATE_WAREHOUSE_R,
  payload: {
    data: Warehouse,
  },
}

export const UPDATE_WAREHOUSE_S = 'UPDATE_WAREHOUSE_S';
export interface WarehouseUpdateWarehouseSucceeded {
  type: typeof UPDATE_WAREHOUSE_S,
}

export const UPDATE_WAREHOUSE_F = 'UPDATE_WAREHOUSE_F';
export interface WarehouseUpdateWarehouseFailed {
  type: typeof UPDATE_WAREHOUSE_F,
}

// ACTIONTYPES
export type WarehouseActionTypes =
| WarehouseGetWarehouseRequested
| WarehouseGetWarehouseSucceeded
| WarehouseSetInput
| WarehouseSetDrawer
| WarehouseCreateWarehouseRequested
| WarehouseCreateWarehouseSucceeded
| WarehouseCreateWarehouseFailed
| WarehouseUpdateWarehouseRequested
| WarehouseUpdateWarehouseSucceeded
| WarehouseUpdateWarehouseFailed;
