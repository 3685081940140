import { useState, useEffect } from 'react';

interface Size {
  width?: number,
  height?: number,
}

const useResize = (): Size => {
  const [windowSize, setWindowSize] = useState<Size>({
    width: undefined,
    height: undefined,
  });

  useEffect(() => {
    const handleResize = (): void => {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    };

    window.addEventListener('resize', handleResize);
    handleResize();

    return () => window.removeEventListener('', handleResize);
  }, []);

  return windowSize;
};

export default useResize;
