import React, { FC, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMinusCircle } from '@fortawesome/pro-light-svg-icons';
import { Button, Drawer, Input, InputNumber, Select } from '@flexboxapps/flbx-webapp-ui';
import { setCreateProductDrawer, setProductInput, createProduct } from 'actions/productActionts';
import { RootState } from 'reducers/rootReducer';
import { StorageType } from 'models';
import './add-product-drawer.scss';

const AddProductDrawer: FC = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const {
    drawerVisible, displayName,
    creatingInProgress, storageTypes,
    storageType, storageUnit, perPieceUnit,
    storageQuantity, variationParents,
    variationParent, defaultQuantity,
    variations,
  } = useSelector(({ product }: RootState) => product);
  const { units, unitCategories } = useSelector(({ unit }: RootState) => unit);
  const { lang } = useSelector(({ app }: RootState) => app);
  const [variationCount, setVariationCount] = useState(0);
  const language = lang.split('-')[0];

  const groupedUnit = unitCategories.map((item) => ({
    label: t(`app.unitType.${item}`),
    value: t(`app.unitType.${item}`),
    group: units
      .filter((unitItem) => unitItem.category === item)
      .map((groupItem) => ({
        label: groupItem.titles[language],
        value: groupItem._id,
      })),
  }));

  const disabledDrawer = (): boolean => {
    switch (storageType) {
      case StorageType.WHOLE:
        return !storageUnit || !displayName;

      case StorageType.MEASURED:
        return !perPieceUnit || !displayName;

      case StorageType.UNFOLDABLE:
        return !storageUnit || !storageQuantity || !perPieceUnit || !displayName;

      default:
        return !storageType;
    }
  };

  const storageUnitRef = useRef<any>(null);
  const perPieceUnitRef = useRef<any>(null);

  useEffect(() => {
    if (storageType === StorageType.WHOLE || storageType === StorageType.UNFOLDABLE) {
      storageUnitRef.current.focus();
    }

    if (storageType === StorageType.MEASURED || storageType === StorageType.UNFOLDABLE) {
      perPieceUnitRef.current.focus();
    }
  }, [storageType]);

  return (
    <Drawer
      className="app-drawer drawer-add-category"
      title={t('drawers.addProduct.title')}
      onClose={() => dispatch(setCreateProductDrawer('create', false))}
      visible={drawerVisible.create}
    >
      <div className="drawer-inner-container">
        <div className="product-drawer-input-container">
          <div className="product-padding-container">
            <Input
              label={t('drawers.addProduct.productName').toUpperCase()}
              placeholder={t('drawers.addProduct.productNamePlaceholder')}
              value={displayName}
              onChange={(event) => { dispatch(setProductInput('displayName', event.target.value)); }}
              borderColor="#F2C94C"
            />
          </div>
          <div className="product-padding-container">
            <Button
              label={t('drawers.addProduct.addVariation')}
              onClick={() => {
                setVariationCount(variationCount + 1);
                dispatch(setProductInput('variations', [...Array(variationCount + 1)].map((_, index) => variations[index])));
              }}
              type="ghost"
            />
          </div>
          {
            [...Array(variationCount)].map((_, index) => (
              <div className="product-padding-container with-icon" key={index.toString()}>
                <Input
                  label={`${index + 1}. ${t('drawers.addProduct.variation').toUpperCase()}`}
                  placeholder={t('drawers.addProduct.variationPlaceholder')}
                  value={variations[index]}
                  onChange={(event) => {
                    dispatch(setProductInput('variations', variations
                      .map((variationItem, variationIndex) => (index === variationIndex ? event.target.value : variationItem))));
                  }}
                  borderColor="#F2C94C"
                />
                <FontAwesomeIcon
                  icon={faMinusCircle}
                  onClick={() => {
                    setVariationCount(variationCount - 1);
                    dispatch(setProductInput('variations', variations.filter((varitionItem, variationIndex) => index !== variationIndex)));
                  }}
                />
              </div>
            ))
          }
          <div className="product-padding-container">
            <Select
              label={t('drawers.addProduct.storageType').toUpperCase()}
              placeholder={t('drawers.addProduct.storageTypePlaceholder')}
              value={storageType}
              options={storageTypes.map((item) => ({
                label: t(`drawers.addProduct.type.${item.label}`),
                value: item.value,
              }))}
              onChange={(value) => { dispatch(setProductInput('storageType', value)); }}
              borderColor="#F2C94C"
              size="large"
            />
          </div>
          {
            variationParents.length ? (
              <Select
                label={t('drawers.addProduct.variationParent').toUpperCase()}
                placeholder={t('drawers.addProduct.variationParentPlaceholder')}
                value={variationParent}
                options={variationParents}
                onChange={(value) => { dispatch(setProductInput('variationParent', value)); }}
                borderColor="#F2C94C"
                size="large"
              />
            ) : null
          }
          {
            (storageType === StorageType.WHOLE || storageType === StorageType.UNFOLDABLE) && (
              <div className="product-padding-container">
                <Select
                  label={t('drawers.addProduct.storageUnit').toUpperCase()}
                  placeholder={t('drawers.addProduct.storageUnitPlaceholder')}
                  value={storageUnit}
                  options={groupedUnit}
                  onChange={(value) => { dispatch(setProductInput('storageUnit', value)); }}
                  borderColor="#F2C94C"
                  size="large"
                  showSearch
                  isGroup
                  ref={storageUnitRef}
                />
              </div>
            )
          }
          {
            (storageType === StorageType.MEASURED || storageType === StorageType.UNFOLDABLE) && (
              <div className="product-padding-container">
                <Select
                  label={t('drawers.addProduct.perPieceUnit').toUpperCase()}
                  placeholder={t('drawers.addProduct.perPieceUnitPlaceholder')}
                  value={perPieceUnit}
                  options={groupedUnit}
                  onChange={(value) => { dispatch(setProductInput('perPieceUnit', value)); }}
                  borderColor="#F2C94C"
                  size="large"
                  showSearch
                  isGroup
                  ref={perPieceUnitRef}
                />
              </div>
            )
          }
          {
            storageType === StorageType.UNFOLDABLE && (
              <div className="product-padding-container">
                <InputNumber
                  label={t('drawers.addProduct.storageQuantity').toUpperCase()}
                  value={storageQuantity}
                  onChange={(value) => { dispatch(setProductInput('storageQuantity', value)); }}
                  min={1}
                  max={1000}
                  step={1}
                  prefix={units.find((item) => item._id === perPieceUnit)?.shortName}
                  borderColor="#F2C94C"
                />
              </div>
            )
          }
          {
            storageType ? (
              <div className="product-padding-container">
                <InputNumber
                  label={t('drawers.addProduct.defaultQuantity').toUpperCase()}
                  value={defaultQuantity}
                  onChange={(value) => { dispatch(setProductInput('defaultQuantity', value)); }}
                  min={1}
                  max={1000}
                  step={1}
                  prefix={units.find((item) => item._id === (storageType === StorageType.WHOLE ? storageUnit : perPieceUnit))?.shortName}
                  borderColor="#F2C94C"
                />
              </div>
            ) : null
          }
        </div>
      </div>
      <div className="drawer-actions">
        <Button
          label={t('app.common.buttons.cancel')}
          disabled={creatingInProgress}
          onClick={() => dispatch(setCreateProductDrawer('create', false))}
          type="ghost"
        />
        <Button
          label={t('app.common.buttons.create')}
          type="secondary"
          disabled={disabledDrawer()}
          loading={creatingInProgress}
          onClick={() => { dispatch(createProduct()); }}
        />
      </div>
    </Drawer>
  );
};

export default AddProductDrawer;
