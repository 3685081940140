import { Sorts } from '@flexboxapps/flbx-webapp-ui';
import { SelectValue } from 'antd/lib/select';
import {
  GET_WAREHOUSE_R, WarehouseGetWarehouseRequested,
  SET_WAREHOUSE_INPUT, WarehouseSetInput,
  SET_WAREHOUSE_DRAWER, WarehouseSetDrawer,
  CREATE_WAREHOUSE_R, WarehouseCreateWarehouseRequested,
  UPDATE_WAREHOUSE_R, WarehouseUpdateWarehouseRequested,
} from 'consts/warehouseActionTypes';
import { Warehouse } from 'reducers/warehouses/initialState';

export const getWarehouses = (value?: Sorts | null, key?: string, term?: string): WarehouseGetWarehouseRequested => ({
  type: GET_WAREHOUSE_R,
  payload: {
    value,
    key,
    term,
  },
});

export const setWarehouseInput = (key: string, value?: string | number | boolean | SelectValue): WarehouseSetInput => ({
  type: SET_WAREHOUSE_INPUT,
  payload: {
    key,
    value,
  },
});

export const setCreateWarehouseDrawer = (key: string, value: boolean): WarehouseSetDrawer => ({
  type: SET_WAREHOUSE_DRAWER,
  payload: {
    key,
    value,
  },
});

export const createWarehouse = (): WarehouseCreateWarehouseRequested => ({
  type: CREATE_WAREHOUSE_R,
});

export const updateWarehouse = (data: Warehouse): WarehouseUpdateWarehouseRequested => ({
  type: UPDATE_WAREHOUSE_R,
  payload: {
    data,
  },
});
