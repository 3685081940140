import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'reducers/rootReducer';
import { logout } from 'actions/userActions';
import { ReactComponent as MapMarker } from 'assets/map-marker.svg';
import { Header, DashboardHeader, DashboardCard } from '@flexboxapps/flbx-webapp-ui';
import { showNotImplementedAlert } from 'components/NotImplemented/showNotImplementedAlert';
import { faWarehouseAlt } from '@fortawesome/pro-solid-svg-icons';
import './dashboard.scss';

const DashboardPage: FC = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { email } = useSelector(({ user }: RootState) => user);
  const usermenu = [{ label: t('app.common.buttons.logout'), action: () => dispatch(logout()) }];
  return (
    <div className="page page-dashboard">
      <Header
        userinfo={email || ''}
        onHamburgerMenuClick={showNotImplementedAlert}
        onLogoClick={() => { }}
        usermenu={usermenu}
        inverse
      />
      <div className="header-container">
        <DashboardHeader
          title={t('pages.dashboard.title')}
          subtitle={t('pages.dashboard.welcomeSubtitle')}
          iconName={faWarehouseAlt}
          backgroundColor="#F2C94C"
          color="#131522"
        />
      </div>
      <div className="dashboard-cards">
        <DashboardCard
          route="/locations"
          title={t('pages.dashboard.infocards.locations')}
          subtitle={t('pages.dashboard.infocards.categorysub')}
          logo={<MapMarker />}
          inverse
        />
        <DashboardCard
          route="/warehouses"
          title={t('pages.dashboard.infocards.warehouses')}
          subtitle={t('pages.dashboard.infocards.categorysub')}
          logo={<MapMarker />}
          inverse
        />
        <DashboardCard
          route="/products"
          title={t('pages.dashboard.infocards.products')}
          subtitle={t('pages.dashboard.infocards.categorysub')}
          logo={<MapMarker />}
          inverse
        />
        <DashboardCard
          route="/stocks"
          title={t('pages.dashboard.infocards.stocks')}
          subtitle={t('pages.dashboard.infocards.categorysub')}
          logo={<MapMarker />}
          inverse
        />
      </div>
    </div>
  );
};

export default DashboardPage;
