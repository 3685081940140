import Amplify from 'aws-amplify';

const apiEndpoints = [
  'warehouses',
  'locations',
  'products',
  'stock',
].map((name) => ({
  name,
  endpoint: process.env.REACT_APP_AWS_API_GATEWAY_URL,
  region: process.env.REACT_APP_AWS_API_GATEWAY_REGION,
}));

const commonEndpoints = [
  'units',
].map((name) => ({
  name,
  endpoint: process.env.REACT_APP_AWS_COMMON_GATEWAY_URL,
  region: process.env.REACT_APP_AWS_API_GATEWAY_REGION,
}));

Amplify.configure({
  Auth: {
    mandatorySignIn: true,
    region: process.env.REACT_APP_AWS_COGNITO_REGION,
    userPoolId: process.env.REACT_APP_AWS_COGNITO_USER_POOL_ID,
    identityPoolId: process.env.REACT_APP_AWS_COGNITO_IDENTITY_POOL_ID,
    userPoolWebClientId: process.env.REACT_APP_AWS_COGNITO_APP_CLIENT_ID,
  },
  Storage: {
    bucket: process.env.REACT_APP_AWS_S3_BUCKET,
    region: process.env.REACT_APP_AWS_S3_REGION,
    identityPoolId: process.env.REACT_APP_AWS_COGNITO_IDENTITY_POOL_ID,
  },
  API: {
    endpoints: [...apiEndpoints, ...commonEndpoints],
  },
});
