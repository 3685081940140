import {
  USER_LOGIN_R, USER_LOGIN_S, USER_LOGIN_F,
  RESTORE_USER_BY_TOKEN_S,
  USER_LOGOUT_S,
  UserActionTypes,
} from 'consts/userActionTypes';
import { initialState, UserState } from './initialState';

const userReducer = (state = initialState, action: UserActionTypes): UserState => {
  switch (action.type) {
    case USER_LOGIN_R:
      return {
        ...state,
        isAuthenticating: true,
        hasAuthenticatingError: false,
      };

    case USER_LOGIN_S: {
      return {
        ...state,
        isAuthenticating: false,
        isAuthenticated: true,
        hasAuthenticatingError: false,
        errorMessage: '',
        email: action.payload.email,
      };
    }

    case RESTORE_USER_BY_TOKEN_S: {
      return {
        ...state,
        email: action.payload.email,
        isAuthenticated: true,
      };
    }

    case USER_LOGIN_F:
      return {
        ...state,
        isAuthenticating: false,
        hasAuthenticatingError: true,
        errorMessage: action.payload.errorMessage,
      };

    case USER_LOGOUT_S:
      return initialState;

    default:
      return state;
  }
};

export default userReducer;
