import React, { FC } from 'react';
import { setConfig } from 'react-hot-loader';
import { Provider, RootStateOrAny } from 'react-redux';
import { Router } from 'react-router-dom';
import { useClearCache } from 'react-clear-cache';
import { hot } from 'react-hot-loader/root';
import App from 'components/App/App';
import UpdateApp from 'components/UpdateApp/UpdateApp';

setConfig({ trackTailUpdates: false });

type RootProps = {
  store: RootStateOrAny;
  history: any;
};

export const Root: FC<RootProps> = ({ store, history }) => {
  const { isLatestVersion } = useClearCache();
  return (
    <Provider store={store}>
      <Router history={history}>
        {!isLatestVersion ? <UpdateApp /> : <App />}
      </Router>
    </Provider>
  );
};

export default hot(Root);
