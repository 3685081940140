import React, { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Table } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { Header, BottomHeader, Search, Sorts } from '@flexboxapps/flbx-webapp-ui';
import { RootState } from 'reducers/rootReducer';
import { logout } from 'actions/userActions';
import { getProducts, setCreateProductDrawer } from 'actions/productActionts';
import { getUnits } from 'actions/unitActions';
import { history } from 'services/config';
import AddProductDrawer from 'components/Product/AddProductDrawer';
import { showNotImplementedAlert } from 'components/NotImplemented/showNotImplementedAlert';
import { ReactComponent as StocksIcon } from 'assets/stocks.svg';
import './product-page.scss';
import { Unit } from 'reducers/units/initialState';

export interface ColumnType {
  key: string;
  dataIndex: string;
  title: string;
  render?: any;
}

const ProductPage: FC = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { email } = useSelector(({ user }: RootState) => user);
  const { products } = useSelector(({ product }: RootState) => product);
  const { lang } = useSelector(({ app }: RootState) => app);

  const [searchValue, setSearchValue] = useState<string>('');
  const [sortKey, setKey] = useState<string>('createdAt');
  const [sortValue, setValue] = useState<Sorts | null | undefined>(Sorts.asc);

  useEffect(() => {
    dispatch(getUnits());
    dispatch(getProducts(sortValue, sortKey, searchValue));
  }, []);

  const stringSort = (value?: Sorts | null): void => {
    setKey('title');
    setValue(value);
    dispatch(getProducts(value, 'title', searchValue));
  };

  const dateSort = (value?: Sorts | null): void => {
    setKey('createdAt');
    setValue(value);
    dispatch(getProducts(value, 'createdAt', searchValue));
  };

  const usermenu: Array<{ label: string, action: () => void }> = [{ label: t('app.common.buttons.logout'), action: () => dispatch(logout()) }];

  const columns: ColumnType[] = [
    {
      title: t('drawers.addProduct.productName'),
      dataIndex: 'displayName',
      key: 'displayName',
    },
    {
      title: t('drawers.addProduct.storageUnit'),
      dataIndex: 'storageUnit',
      key: 'storageUnit',
    },
    {
      title: t('drawers.addProduct.storageType'),
      dataIndex: 'storageType',
      key: 'storageType',
    },
  ];

  const language: string = lang.split('-')[0];

  const getStorageUnit = (storageUnit?: Unit, perPieceUnit?: Unit): string => {
    let unitString = '';
    if (storageUnit) {
      unitString += `${storageUnit?.titles[language]} `;
    }
    if (perPieceUnit) {
      unitString += ` ${perPieceUnit?.titles[language]}`;
    }
    return unitString.split('  ').join(' | ');
  };

  return (
    <div className="page page-product">
      <Header
        userinfo={email || ''}
        onHamburgerMenuClick={showNotImplementedAlert}
        onLogoClick={() => { history.push('/dashboard'); }}
        usermenu={usermenu}
        large
        inverse
      />
      <div className="product-page-body">
        <AddProductDrawer />
        <BottomHeader
          title={t('pages.products.title').toUpperCase()}
          subtitle={t('pages.products.subtitle')}
          buttonTitle={t('pages.products.buttonTitle')}
          breadcrumbs={[{ title: 'Dashboard', link: '/dashboard' }]}
          onClick={() => { dispatch(setCreateProductDrawer('create', true)); }}
          icon={<StocksIcon />}
          inverse
        />
        <div className="products-search-container">
          <Search
            onStringSort={stringSort}
            onDateSort={dateSort}
            defaultString={null}
            defaultDate={Sorts.asc}
            onSearchTermBlur={() => { }}
            onSearchTermChange={(value) => { setSearchValue(value); dispatch(getProducts(sortValue, sortKey, value)); }}
            activeColor="#F2C94C"
          />
          <div className="products-container">
            <Table
              columns={columns}
              loading={!products.length}
              locale={{ emptyText: t('app.common.noData') }}
              dataSource={products.length ? products.map((item, index) => ({
                wholeProduct: item,
                key: index.toString(),
                displayName: item.displayName,
                storageUnit: getStorageUnit(item.storageUnit, item.perPieceUnit),
                storageType: t(`drawers.addProduct.type.${item.storageType}`),
                hasVariations: item.hasVariations,
                variationParent: item.variationParent,
                variations: item.hasVariations
                  ? products.filter((productItem) => item._id === productItem.variationParent
                    || (productItem.variationParent && item.parent === productItem.variationParent))
                    .map((variationItem) => variationItem.displayName)
                  : [],
              })).filter((dataItem) => !dataItem.variationParent) : []}
              pagination={false}
              expandable={{
                expandedRowRender: (record) => <div>{record.variations.join(', ')}</div>,
                rowExpandable: (record) => !!record.hasVariations,
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProductPage;
