import { Sorts } from '@flexboxapps/flbx-webapp-ui';
import {
  GET_LOCATION_R, LocationGetLocationRequested,
  SET_LOCATION_INPUT, LocationSetInput,
  SET_LOCATION_DRAWER, LocationSetDrawer,
  CREATE_LOCATION_R, LocationCreateLocationRequested,
  UPDATE_LOCATION_R, LocationUpdateLocationRequested,
} from 'consts/locationActionTypes';
import { Location } from 'reducers/locations/initialState';

export const getLocations = (value?: Sorts | null, key?: string, term?: string): LocationGetLocationRequested => ({
  type: GET_LOCATION_R,
  payload: {
    value,
    key,
    term,
  },
});

export const setLocationInput = (key: string, value?: string | number | boolean): LocationSetInput => ({
  type: SET_LOCATION_INPUT,
  payload: {
    key,
    value,
  },
});

export const setCreateLocationDrawer = (key: string, value: boolean): LocationSetDrawer => ({
  type: SET_LOCATION_DRAWER,
  payload: {
    key,
    value,
  },
});

export const createLocation = (): LocationCreateLocationRequested => ({
  type: CREATE_LOCATION_R,
});

export const updateLocation = (data: Location): LocationUpdateLocationRequested => ({
  type: UPDATE_LOCATION_R,
  payload: {
    data,
  },
});
