import { Sorts } from '@flexboxapps/flbx-webapp-ui';
import { SelectValue } from 'antd/lib/select';
import { Product } from 'reducers/products/initialState';

// PRODUCT ACTIONS
export const GET_PRODUCT_R = 'GET_PRODUCT_R';
export interface ProductGetProductRequested {
  type: typeof GET_PRODUCT_R,
  payload: {
    value?: Sorts | null,
    key?: string,
    term?: string,
  },
}

export const GET_PRODUCT_S = 'GET_PRODUCT_S';
export interface ProductGetProductSucceeded {
  type: typeof GET_PRODUCT_S,
  payload: {
    products: Product[],
  },
}

export const GET_PRODUCT_F = 'GET_PRODUCT_F';

export const SET_PRODUCT_INPUT = 'SET_PRODUCT_INPUT';

export interface ProductSetInput {
  type: typeof SET_PRODUCT_INPUT,
  payload: {
    key: string,
    value?: string | number | boolean | SelectValue,
  },
}

export const SET_PRODUCT_DRAWER = 'SET_PRODUCT_DRAWER';

export interface ProductSetDrawer {
  type: typeof SET_PRODUCT_DRAWER,
  payload: {
    key: string,
    value: boolean,
  },
}

export const CREATE_PRODUCT_R = 'CREATE_PRODUCT_R';
export interface ProductCreateProductRequested {
  type: typeof CREATE_PRODUCT_R,
}

export const CREATE_PRODUCT_S = 'CREATE_PRODUCT_S';
export interface ProductCreateProductSucceeded {
  type: typeof CREATE_PRODUCT_S,
}

export const CREATE_PRODUCT_F = 'CREATE_PRODUCT_F';
export interface ProductCreateProductFailed {
  type: typeof CREATE_PRODUCT_F,
}

export const UPDATE_PRODUCT_R = 'UPDATE_PRODUCT_R';
export interface ProductUpdateProductRequested {
  type: typeof UPDATE_PRODUCT_R,
  payload: {
    data: Product,
  },
}

export const UPDATE_PRODUCT_S = 'UPDATE_PRODUCT_S';
export interface ProductUpdateProductSucceeded {
  type: typeof UPDATE_PRODUCT_S,
}

export const UPDATE_PRODUCT_F = 'UPDATE_PRODUCT_F';
export interface ProductUpdateProductFailed {
  type: typeof UPDATE_PRODUCT_F,
}

// ACTIONTYPES
export type ProductActionTypes =
| ProductGetProductRequested
| ProductGetProductSucceeded
| ProductSetInput
| ProductSetDrawer
| ProductCreateProductRequested
| ProductCreateProductSucceeded
| ProductCreateProductFailed
| ProductUpdateProductRequested
| ProductUpdateProductSucceeded
| ProductUpdateProductFailed;
