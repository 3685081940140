import React, { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Header, BottomHeader, Search, Sorts } from '@flexboxapps/flbx-webapp-ui';
import { RootState } from 'reducers/rootReducer';
import { logout } from 'actions/userActions';
import { getWarehouses, setCreateWarehouseDrawer } from 'actions/warehouseActions';
import { getLocations } from 'actions/locationActions';
import { Warehouse } from 'reducers/warehouses/initialState';
import { history } from 'services/config';
import LocationCard from 'components/LocationCard/LocationCard';
import AddWarehouseDrawer from 'components/Warehouse/AddWarehouseDrawer';
import { showNotImplementedAlert } from 'components/NotImplemented/showNotImplementedAlert';
import { ReactComponent as LocationsIcon } from 'assets/locations.svg';
import './warehouse-page.scss';
import { Location } from 'reducers/locations/initialState';
import UpdateWarehouseDrawer from 'components/Warehouse/UpdateWarehouseDrawer';

const WarehousePage: FC = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { email } = useSelector(({ user }: RootState) => user);
  const { warehouses } = useSelector(({ warehouse }: RootState) => warehouse);
  const { locations } = useSelector(({ location }: RootState) => location);
  const [searchValue, setSearchValue] = useState<string>('');
  const [sortKey, setKey] = useState<string>('createdAt');
  const [sortValue, setValue] = useState<Sorts | null | undefined>(Sorts.asc);
  const [selectedWarehouse, setWarehouse] = useState<Warehouse>(warehouses[0]);

  useEffect(() => {
    if (!locations.length) {
      dispatch(getLocations(sortValue, sortKey, searchValue));
    }
    dispatch(getWarehouses(sortValue, sortKey, searchValue));
  }, []);

  const stringSort = (value?: Sorts | null): void => {
    setKey('title');
    setValue(value);
    dispatch(getWarehouses(value, 'title', searchValue));
  };

  const dateSort = (value?: Sorts | null): void => {
    setKey('createdAt');
    setValue(value);
    dispatch(getWarehouses(value, 'createdAt', searchValue));
  };

  const usermenu = [{ label: t('app.common.buttons.logout'), action: () => dispatch(logout()) }];
  return (
    <div className="page page-warehouse">
      <Header
        userinfo={email || ''}
        onHamburgerMenuClick={showNotImplementedAlert}
        onLogoClick={() => { history.push('/dashboard'); }}
        usermenu={usermenu}
        large
        inverse
      />
      <div className="warehouse-page-body">
        <AddWarehouseDrawer />
        <UpdateWarehouseDrawer selectedWarehouse={selectedWarehouse} />
        <BottomHeader
          title={t('pages.warehouses.title').toUpperCase()}
          subtitle={t('pages.warehouses.subtitle')}
          buttonTitle={t('pages.warehouses.buttonTitle')}
          breadcrumbs={[{ title: 'Dashboard', link: '/dashboard' }]}
          onClick={() => { dispatch(setCreateWarehouseDrawer('create', true)); }}
          icon={<LocationsIcon />}
          inverse
        />
        <div className="warehouses-search-container">
          <Search
            onStringSort={stringSort}
            onDateSort={dateSort}
            defaultString={null}
            defaultDate={Sorts.asc}
            onSearchTermBlur={() => { }}
            onSearchTermChange={(value) => { setSearchValue(value); dispatch(getWarehouses(sortValue, sortKey, value)); }}
            activeColor="#F2C94C"
          />
          <div className="warehouses-container">
            {warehouses && warehouses.length ? warehouses.map((item: Warehouse, index: number) => (
              <LocationCard
                location={`${item.title} ${locations.find((location: Location) => location._id === item.location)?.title} ${item.type}`}
                onClick={() => {
                  dispatch(setCreateWarehouseDrawer('update', true));
                  setWarehouse(item);
                }}
                key={index.toString()}
              />
            )) : null}
            <div className="warehouse-card-placeholder" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default WarehousePage;
