import { Sorts } from '@flexboxapps/flbx-webapp-ui';
import { SelectValue } from 'antd/lib/select';
import {
  GET_PRODUCT_R, ProductGetProductRequested,
  SET_PRODUCT_INPUT, ProductSetInput,
  SET_PRODUCT_DRAWER, ProductSetDrawer,
  CREATE_PRODUCT_R, ProductCreateProductRequested,
  UPDATE_PRODUCT_R, ProductUpdateProductRequested,
} from 'consts/productActionTypes';
import { Product } from 'reducers/products/initialState';

export const getProducts = (value?: Sorts | null, key?: string, term?: string): ProductGetProductRequested => ({
  type: GET_PRODUCT_R,
  payload: {
    value,
    key,
    term,
  },
});

export const setProductInput = (key: string, value?: string | number | boolean | SelectValue | string[]): ProductSetInput => ({
  type: SET_PRODUCT_INPUT,
  payload: {
    key,
    value,
  },
});

export const setCreateProductDrawer = (key: string, value: boolean): ProductSetDrawer => ({
  type: SET_PRODUCT_DRAWER,
  payload: {
    key,
    value,
  },
});

export const createProduct = (): ProductCreateProductRequested => ({
  type: CREATE_PRODUCT_R,
});

export const updateProduct = (data: Product): ProductUpdateProductRequested => ({
  type: UPDATE_PRODUCT_R,
  payload: {
    data,
  },
});
