import React, { FC } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Route } from 'react-router-dom';
import { RootState } from 'reducers/rootReducer';
import { restoreUserByToken } from 'actions/userActions';
import { history } from 'services/config';

type AppRouteProps = {
  component: React.ComponentType,
  exact: boolean,
  path: string,
};

const AppRoute: FC<AppRouteProps> = ({ component: Component, ...rest }) => {
  const dispatch = useDispatch();
  const { isAuthenticated } = useSelector(({ user }: RootState) => user);
  if (isAuthenticated) {
    return (
      <Route component={Component} {...rest} />
    );
  }

  const next = history.location.pathname + history.location.search;
  dispatch(restoreUserByToken(next));
  return null;
};

export default AppRoute;
