import LoginPage from 'routes/LoginPage/LoginPage';
import DashboardPage from 'routes/DashboardPage/DashboardPage';
import LocationPage from 'routes/LocationPage/LocationPage';
import WarehousePage from 'routes/WarehousePage/WarehousePage';
import ProductPage from 'routes/ProductPage/ProductPage';
import StockPage from 'routes/StockPage/StockPage';

const Routes = {
  LoginPage,
  DashboardPage,
  LocationPage,
  WarehousePage,
  ProductPage,
  StockPage,
};

export default Routes;
