import React, { FC, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Button, Drawer, Input } from '@flexboxapps/flbx-webapp-ui';
import { setCreateLocationDrawer, updateLocation } from 'actions/locationActions';
import { Location } from 'reducers/locations/initialState';
import { RootState } from 'reducers/rootReducer';
import './add-location-drawer.scss';

export interface UpdateLocationDrawerProps {
  selectedLocation: Location;
}

const UpdateLocationDrawer: FC<UpdateLocationDrawerProps> = ({ selectedLocation }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [data, setData] = useState<Location | null>(null);

  useEffect(() => {
    setData(selectedLocation);
  }, [selectedLocation]);

  const {
    drawerVisible, updatingInProgress,
  } = useSelector(({ location }: RootState) => location);

  const handleDataChange = (key: string, value: string) => {
    if (data) {
      setData({
        ...data,
        [key]: value,
      });
    }
  };

  return data ? (
    <Drawer
      className="app-drawer drawer-add-category"
      title={t('drawers.addLocation.updateTitle')}
      onClose={() => dispatch(setCreateLocationDrawer('update', false))}
      visible={drawerVisible.update}
    >
      <div className="drawer-inner-container">
        <div className="location-drawer-input-container">
          <Input
            label={t('drawers.addLocation.locationName').toUpperCase()}
            placeholder={t('drawers.addLocation.locationNamePlaceholder')}
            value={data.title}
            onChange={(event) => { handleDataChange('title', event.target.value); }}
            borderColor="#F2C94C"
          />
        </div>
      </div>
      <div className="drawer-actions">
        <Button
          label={t('app.common.buttons.cancel')}
          disabled={updatingInProgress}
          onClick={() => dispatch(setCreateLocationDrawer('update', false))}
          type="ghost"
        />
        <Button
          label={t('app.common.buttons.update')}
          type="secondary"
          disabled={data.title === ''}
          loading={updatingInProgress}
          onClick={() => { dispatch(updateLocation(data)); }}
        />
      </div>
    </Drawer>
  ) : null;
};

export default UpdateLocationDrawer;
