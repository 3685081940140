import React, { FC, useState } from 'react';
import { RootStateOrAny, useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { faUser, faKey } from '@fortawesome/pro-duotone-svg-icons';
import { IconInput, Button } from '@flexboxapps/flbx-webapp-ui';
import { login } from 'actions/userActions';

const LoginForm: FC = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState({
    emailError: false,
    passwordError: false,
  });
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const { hasAuthenticatingError, isAuthenticating } = useSelector((store: RootStateOrAny) => store.user);

  const setEmailError = () => {
    if (!email || email === '') {
      setError({ ...error, emailError: true });
      return true;
    }
    setError({ ...error, emailError: false });
    return null;
  };

  const setPasswordError = () => {
    if (!password || password === '') {
      setError({ ...error, passwordError: true });
      return true;
    }
    setError({ ...error, passwordError: false });
    return null;
  };

  const handleLogin = () => {
    if (email && password && !setEmailError() && !setPasswordError()) {
      dispatch(login(email, password, '/dashboard'));
    }
  };

  return (
    <div className="login-container">
      <div className="input-container">
        {
          error.emailError && (
            <div className="error-message">
              {t('pages.login.errors.emptyEmail')}
            </div>
          )
        }
        <IconInput
          value={email}
          placeholder={t('pages.login.inputs.email')}
          iconName={faUser}
          onChange={(e: any) => setEmail(e.target.value)}
          onBlur={() => { setEmailError(); }}
          borderColor="#F2C94C"
        />
      </div>
      <div className="input-container">
        {
          error.passwordError && (
            <div className="error-message">
              {t('pages.login.errors.emptyPassword')}
            </div>
          )
        }
        <IconInput
          value={password}
          placeholder={t('pages.login.inputs.password')}
          iconName={faKey}
          onChange={(e: any) => setPassword(e.target.value)}
          onBlur={() => { setPasswordError(); }}
          type="password"
          borderColor="#F2C94C"
        />
      </div>
      <div className="login-button-container">
        {
          hasAuthenticatingError && (
            <div className="error-message">
              {t('pages.login.errors.badCredentials')}
            </div>
          )
        }
        <Button label={t('pages.login.inputs.login')} onClick={handleLogin} type="secondary" loading={isAuthenticating} />
      </div>
    </div>
  );
};

export default LoginForm;
