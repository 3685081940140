import { Unit } from 'reducers/units/initialState';

// UNIT ACTIONS
export const GET_UNIT_R = 'GET_UNIT_R';
export interface UnitGetUnitRequested {
  type: typeof GET_UNIT_R,
}

export const GET_UNIT_S = 'GET_UNIT_S';
export interface UnitGetUnitSucceeded {
  type: typeof GET_UNIT_S,
  payload: {
    units: Unit[],
  },
}

export const GET_UNIT_F = 'GET_UNIT_F';

// ACTIONTYPES
export type UnitActionTypes =
| UnitGetUnitRequested
| UnitGetUnitSucceeded;
