import React, { FC } from 'react';
import { Route } from 'react-router-dom';

type PublicRouteProps = {
  layout: React.ComponentType,
  component: React.ComponentType,
  exact: boolean,
  path: string,
};

const PublicRoute: FC<PublicRouteProps> = ({ layout, component: Component, ...rest }) => (
  <Route
    {...rest}
    render={(props: any) => (React.createElement(layout, props, <Component {...props} />))}
  />
);

export default PublicRoute;
